import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export interface IMessageBoxProps {
    message: string,
    open: boolean,
    onClose?: () => void;
}


function tryParseJSONObject(jsonString: string) {
    try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};

export const MessageBox: React.FC<IMessageBoxProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [errorObject, setErrorObject] = useState<any>();

    useEffect(() => {
        setOpen(props.open);
        setMessage(props.message);

        //debugger
        try {
            if (props.message && props.message !== "") {

                let srtMessage = "";
                if (tryParseJSONObject(props.message)) {
                    srtMessage = JSON.parse(props.message);
                }
                else srtMessage = props.message;
                //const srtMessage = JSON.parse(props.message);
                setErrorObject(srtMessage);
                console.log(srtMessage);
            }
        } catch (error) {
            //debugger
        }

    }, [props]);

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) { props.onClose(); }
    };

    return (<div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Box display='flex' alignItems='center'>
                    <Typography variant='h5'>
                        {"Something when wrong"}
                    </Typography>
                    <SentimentVeryDissatisfiedIcon />
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        !errorObject &&
                        <>
                            <Typography variant="body1" color="text.secondary">
                                {message}
                            </Typography>
                        </>
                    }
                    {
                        errorObject &&
                        <>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                {errorObject.title + " (Error : " + errorObject.status + ")"}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {errorObject.detail}
                            </Typography>
                        </>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>Close</Button>
            </DialogActions>
        </Dialog>
    </div>);
}