import { Box, Button, ClickAwayListener, Collapse, Grid, IconButton, Link, Paper, Popover, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IAuthShuttle, ICustomerDetailsShuttle, ICustomerEntitlementsShuttle, IEntitlementsShuttle, IInstance, SocSentinelWebApi } from "../../Services/soc-sentinel-web-api";
import useIds from "../../Services/idsHook";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { format } from 'date-fns'
import useEntitlementTools from "../../Services/tools";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import KeyIcon from '@mui/icons-material/Key';
import { useNavigate } from "react-router-dom";
import { MessageBox } from "../Tools/MessageBox";
import { EntitlementDialog, IEntitlementDialog } from "../Entitlement/EntitlementDialog";
import { Block } from "@mui/icons-material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Console } from "console";

export interface CustomerEntitlementListProps {
    vendorId: number,
    pageSize: number,
    customerId?: string,
    crmId: string
}

const padding = '6px 5px';

export const CustomerEntitlementList: React.FC<CustomerEntitlementListProps> = (props) => {

    const [entitlementsReload, setEntitlementsReload] = useState<boolean>(false);
    const [entitlements, setEntitlements] = useState<any | undefined>(undefined);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [getIds, secretKey] = useIds();
    const [crmId, setCrmId] = useState<string>("");
    const [customerId, setCustomerId] = useState<string>();
    const [authShuttle, setAuthShuttle] = useState<IAuthShuttle | undefined>(undefined);
    const [name, setName] = useState<string>("");
    const [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE, getLineItemType] = useEntitlementTools();
    const [openModalEntitlement, setOpenModalEntitlement] = useState(false);

    const [pageSize, setPageSize] = useState<number>(20);

    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [filterSetted, setFilterSetted] = useState<boolean>(false);
    const [filterKeyID, setFilterKeyID] = useState<string>("");
    const [filterKeyIDTemp, setFilterTemp] = useState<string>("");
    const [openTooltipAA, setOpenTooltipAA] = React.useState(false);


    useEffect(() => {

        let ids = getIds();

        let tempAuthShuttle: IAuthShuttle = { cryptedIds: ids.cryptedIds }
        setAuthShuttle(tempAuthShuttle);

        let restAPI = new SocSentinelWebApi();

        if (props.customerId || props.crmId) {
            setCustomerId(props.customerId);
            setCrmId(props.crmId);
            loadEntitlements(tempAuthShuttle, props.customerId, props.crmId, props.pageSize, filterKeyID);

            let shuttleDetails: ICustomerDetailsShuttle = {
                authShuttle: tempAuthShuttle,
                customerId: props.customerId,
                crmId: props.crmId
            }

            if (!props.customerId) {
                restAPI.CustomerGetID(shuttleDetails)
                    .then((u) => { setCustomerId(u.toString()); })
                    .catch((ex: any) => {
                        //debugger
                        setAlertMessage(ex);
                        setAlertOpen(true);
                    });
            }

            restAPI.CustomerDetails(shuttleDetails)
                .then((u) => {
                    //debugger
                    setName((u.type === 0 ? u.name : u.defaultContact.lastName + " " + u.defaultContact.firstName));
                    setCrmId(u.crmId);
                })
                .catch((ex: any) => {
                    //debugger
                    setAlertMessage(ex);
                    setAlertOpen(true);
                });
        }

    }, [props.crmId, props.customerId]);

    const loadEntitlements = (authShuttle: IAuthShuttle, customerId: string | undefined, crmId: string, pageSize: number, keyId: string) => {

        setEntitlementsReload(true);
        let restAPI = new SocSentinelWebApi();

        let shuttleEntitlements: ICustomerEntitlementsShuttle = {
            authShuttle: authShuttle,
            customerId: customerId,
            crmId: crmId,
            pageSize: pageSize,
            keyId: keyId
        }

        restAPI.CustomerEntitlements(shuttleEntitlements)
            .then((u) => {
                u.entitlementsFound.forEach((element: any) => {
                    if (element.keyID.startsWith('KeyNull_')) element.keyID = null;
                });
                setEntitlements(u);
                setEntitlementsReload(false);
            })
            .catch((a) => {
                //debugger;
                setAlertMessage(a);
                setAlertOpen(true);
            });
    }

    const exportXLSX = (a: IInstance | undefined) => {

        const data = [
            ['CREATED_DATE', 'CREATED_BY', 'CUSTOMER_NAME', 'EID', 'KEY_ID', 'ID', 'ACTIF', 'STATE', 'PRODUCTS', 'DESCRIPTION', 'REF_ID1', 'REF_ID2', 'LICENSE_TYPE', 'EXPIRATION_DATE', 'CONCURRENT_INSTANCES', 'CUSTOMER_EMAIL']
        ];

        entitlements.entitlementsFound?.forEach((ent: any) => {

            let products: string | null = null;

            ent.entitlements[0]?.entitlementItem[0]?.itemProduct?.forEach((k: any) => {
                if (products) products += "\n" + k.product.productName;
                else products = k.product.productName;
            });

            data.push([
                format(new Date(ent.entitlements[0].createDate), 'dd/MM/yyyy'),
                ent.createdBy,
                name,
                ent.eid,
                ent.keyID,
                ent.entitlements[0].entId,
                ent.entitlements[0].enabled,
                getState(ent),
                products,
                ent.entitlements[0].description,
                ent.refId1,
                ent.refId2,
                getLICENSE_TYPE(ent.entitlements[0]),
                getEXPIRATION_DATE(ent.entitlements[0]),
                getCONCURRENT_INSTANCES(ent.entitlements[0]),
                ent.entitlements[0].customerEmail
            ]);
        });

        let fileName = name + ' - entitlements.xlsx';
        generateAndDownloadXlsx(data, fileName);
    };

    function generateAndDownloadXlsx(data: any[][], filename: string): void {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille 1');
        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([xlsxFile], { type: 'application/octet-stream' }), filename);
    }

    const openAddEntitlment = (a: IInstance | undefined) => { setOpenModalEntitlement(true); };

    // const openDuplicateEntitlment = (entId: number | undefined) => {
    //     setEntIdCopy(entId);
    //     setOpenModalDuplicateEntitlement(true);
    // };

    const handleAlerteClose = () => { setAlertOpen(false); };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const filterByKeyId = () => {
        if (filterKeyID !== filterKeyIDTemp) {
            setFilterSetted(true);
            setFilterKeyID(filterKeyIDTemp);
            setAnchorEl(null);
            if (authShuttle) {
                setEntitlements(undefined);
                loadEntitlements(authShuttle, props.customerId, props.crmId, pageSize, filterKeyIDTemp);
            }
        }
    }

    const resetFilterByKeyID = () => {
        if (filterKeyID !== "") {
            setFilterSetted(false);
            setAnchorEl(null);
            setFilterVisible(false);
            setFilterKeyID("");
            setFilterTemp("");

            if (authShuttle) {
                setEntitlements(undefined);
                loadEntitlements(authShuttle, props.customerId, props.crmId, pageSize, "");
            }
        }
    }

    const openPopoverFilterByKeyId = Boolean(anchorEl);
    const idPopoverFilterByKeyId = openPopoverFilterByKeyId ? 'simple-popover' : undefined;

    return (<>
        <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
        {
            customerId &&
            <EntitlementDialog
                title="Entitlement creation"
                open={openModalEntitlement}
                customerId={+customerId}
                vendorId={props.vendorId}
                onClose={(e) => {
                    if (e.dataCreatedOrOpdated === true) {
                        if (authShuttle) loadEntitlements(authShuttle, customerId, crmId, pageSize, filterKeyID);
                    }
                    setOpenModalEntitlement(false);
                }} />
        }
        {/* <EntitlementDialog
            open={openModalDuplicateEntitlement}
            vendorId={props.vendorId}
            isBlank={true}
            entId={entIdCopy}
            onClose={(e) => {
                if (e.dataCreatedOrOpdated === true) {
                    if (authShuttle) loadEntitlements(authShuttle, customerId, crmId, pageSize, filterKeyID);
                }
                setOpenModalDuplicateEntitlement(false);
            }} /> */}
        <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            {
                (!entitlements || entitlementsReload) &&
                <React.Fragment>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </React.Fragment>
            }
            {
                (entitlements && !entitlementsReload) &&
                <>
                    <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ padding: '0px' }} >
                                        <Tooltip
                                            followCursor
                                            placement="right-start"
                                            enterDelay={1000}
                                            leaveDelay={50}
                                            title="Add entitlement">
                                            <IconButton onClick={(e) => { openAddEntitlment(undefined); }}>
                                                <AddCircleOutlineOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {/* <Tooltip
                                            followCursor
                                            placement="right-start"
                                            enterDelay={1000}
                                            leaveDelay={50}
                                            title="Duplicate entitlement">
                                            <IconButton onClick={(e) => { openDuplicateEntitlment(4811); }}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip> */}
                                    </TableCell>
                                    <TableCell
                                        sx={{ padding: "6px 0px" }}
                                        onMouseEnter={(e) => {
                                            setFilterVisible(true);
                                        }}
                                        onMouseLeave={(e) => {
                                            if (!openPopoverFilterByKeyId && !filterSetted)
                                                setFilterVisible(false);
                                        }}
                                    >
                                        <Grid container
                                            spacing={0}
                                            alignItems="center"
                                        >
                                            <Grid item xs={11}>
                                                <Box sx={{ margin: '0px 10px 0px 0px' }}>KeyID</Box>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Popover
                                                    id={idPopoverFilterByKeyId}
                                                    open={openPopoverFilterByKeyId}
                                                    anchorEl={anchorEl}
                                                    onClose={(e) => {
                                                        if (filterKeyID === "") {
                                                            setFilterSetted(false);
                                                            setFilterVisible(false);
                                                        }
                                                        setFilterTemp(filterKeyID);
                                                        setAnchorEl(null);
                                                    }}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                    PaperProps={{
                                                        style: { width: '400px' },
                                                    }}
                                                >
                                                    <React.Fragment>
                                                        <Box sx={{
                                                            textAlign: "center"
                                                        }}>
                                                            <Paper elevation={24}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="h6" display={"inline"}>
                                                                            Filter by KeyID
                                                                        </Typography>
                                                                        <Typography variant="caption" display={"inline"} sx={{ margin: '0px 0px 0px 5px', fontStyle: 'italic', color: 'grey' }}>
                                                                            (start with)
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            id="keyId"
                                                                            name="keyId"
                                                                            autoComplete="keyId"
                                                                            variant={"outlined"}
                                                                            value={filterKeyIDTemp || ''}
                                                                            style={{ width: "250px" }}
                                                                            onChange={(event: any) => {
                                                                                event.stopPropagation();
                                                                                setFilterTemp(event.target.value);
                                                                            }}
                                                                            onKeyDown={(ev) => {
                                                                                if (ev.key === 'Enter') {
                                                                                    ev.stopPropagation();
                                                                                    filterByKeyId();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Button
                                                                            onClick={(e) => {
                                                                                if (filterKeyID === "") {
                                                                                    setFilterSetted(false);
                                                                                    setFilterVisible(false);
                                                                                }
                                                                                setFilterTemp(filterKeyID);
                                                                                setAnchorEl(null);
                                                                            }}
                                                                            autoFocus
                                                                        >Cancel</Button>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Button
                                                                            onClick={(e) => {
                                                                                resetFilterByKeyID();
                                                                            }}
                                                                            autoFocus
                                                                        >Reset</Button>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Button
                                                                            onClick={(e) => {
                                                                                filterByKeyId();
                                                                            }}
                                                                        >Search</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Box>
                                                    </React.Fragment>
                                                </Popover>
                                                {
                                                    (filterVisible || openPopoverFilterByKeyId) &&
                                                    <Tooltip
                                                        followCursor
                                                        placement="right-start"
                                                        enterDelay={1000}
                                                        leaveDelay={50}
                                                        title="Filter by KeyID">
                                                        <IconButton
                                                            sx={{ margin: '0px 0px 0px 0px', padding: "0px" }} size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setAnchorEl(e.currentTarget);
                                                            }}>
                                                            <FilterAltIcon fontSize="small" color={filterSetted ? "action" : "disabled"} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        </Grid>

                                    </TableCell>
                                    <TableCell sx={{ padding: '0px' }} />
                                    <TableCell sx={{ padding: { padding } }}>Created On</TableCell>
                                    <TableCell sx={{ padding: { padding } }}>Product name</TableCell>
                                    <TableCell sx={{ padding: '0px' }} />
                                    <TableCell sx={{ padding: { padding } }}>RefID1</TableCell>
                                    <TableCell sx={{ padding: { padding } }}>License Type</TableCell>
                                    <TableCell sx={{ padding: { padding } }}>Expiration date</TableCell>
                                    <TableCell sx={{ padding: { padding } }} align="right">Instances</TableCell>
                                    <TableCell sx={{ padding: { padding } }}>Email</TableCell>
                                    <TableCell sx={{ padding: '0px' }} width={40}>
                                        <Tooltip
                                            followCursor
                                            placement="right-start"
                                            enterDelay={1000}
                                            leaveDelay={50}
                                            title="Export Excel">
                                            <IconButton onClick={(e) => { exportXLSX(undefined); }}>
                                                <ReceiptLongIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    entitlements.entitlementsFound?.map((entitlement: any) => (
                                        <React.Fragment key={entitlement.guid}>
                                            <Row
                                                row={entitlement}
                                                vendorId={props.vendorId}
                                                onClose={(e: IEntitlementDialog) => {
                                                    //debugger
                                                    if (e.dataCreatedOrOpdated === true) {
                                                        if (authShuttle) loadEntitlements(authShuttle, customerId, crmId, pageSize, filterKeyID);
                                                    }
                                                }}
                                                customerCrmId={crmId}
                                                customerName={name}
                                            />
                                        </React.Fragment>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PartialResult
                        returnedCount={entitlements.returnedCount}
                        totalCount={entitlements.totalCount}
                        onChange={(a: any) => {
                            //debugger
                            if (authShuttle) {
                                setEntitlements(undefined);
                                let tempPageSize = pageSize + 20;
                                setPageSize(tempPageSize);
                                loadEntitlements(authShuttle, props.customerId, props.crmId, tempPageSize, filterKeyID);
                            }
                        }} />
                </>
            }
        </Paper>
    </>);

}

export interface PartialResultProps {
    returnedCount: number | undefined;
    totalCount: number | undefined;
    onChange?: (filter: any) => void;
}

function PartialResult(props: PartialResultProps) {
    const [full, setFull] = React.useState(true);
    useEffect(() => {
        if (props) { setFull(props.returnedCount === props.totalCount); }
    }, [props]);
    return (
        <>
            <React.Fragment>
                {
                    !full &&
                    <Button
                        style={{ display: "block", textAlign: "center" }}
                        type="submit"
                        fullWidth variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => {
                            if (props.onChange) {
                                props.onChange({ count: 30 });
                            }
                        }}>
                        <Box>{"This result is calculated from the last " + props.returnedCount + " elements out of " + props.totalCount}</Box>
                        <Box>SEE MORE (the calculation can take a very long time)</Box>
                    </Button>
                }

            </React.Fragment>
        </>
    );
}

function Row(props: any) {
    const [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE] = useEntitlementTools();
    const [open, setOpen] = React.useState(false);
    const [row, setRow] = useState<any>(undefined);
    const [openModalDuplicateEntitlement, setOpenModalDuplicateEntitlement] = useState(false);
    const [entIdCopy, setEntIdCopy] = React.useState<number | undefined>(undefined);


    useEffect(() => {
        if (props) {
            const cloneRow = Object.assign({}, props.row);
            setRow(cloneRow);
        }
    }, [props]);

    const OpenDetails = (keyID: any) => { setOpen(!open); }
    const [openTooltip, setOpenTooltip] = useState(false);
    const handleTooltipClose = () => { setOpenTooltip(false); };
    const handleTooltipOpen = () => { setOpenTooltip(true); };

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #a1a1a1',
        },
    }));

    const openDuplicateEntitlment = (entId: number | undefined) => {
        setEntIdCopy(entId);
        setOpenModalDuplicateEntitlement(true);
    };

    const navigate = useNavigate();
    return (
        <>
            <EntitlementDialog
                title="Entitlement duplication"
                open={openModalDuplicateEntitlement}
                vendorId={props.vendorId}
                isBlank={true}
                entId={entIdCopy}
                onClose={(e) => {
                    if (e.dataCreatedOrOpdated === true) {
                        if (props.onClose) { props.onClose(e); }
                    }
                    setOpenModalDuplicateEntitlement(false);
                }} />

            {
                row &&
                <React.Fragment>
                    <TableRow
                        key={row.guid}
                        hover
                        sx={{
                            '& > *': { borderBottom: 'unset' },
                            "&.MuiTableRow-root:hover": {
                                cursor: "pointer"
                            }
                        }}
                        onClick={(e) => { navigate("/sentinel/entitlements/" + row.entitlements[0].entId); }}
                    >
                        <TableCell sx={{ padding: '0px' }} scope="row">
                            {
                                <IconButton aria-label="expand row" size="small" onClick={(e) => {
                                    e.stopPropagation();
                                    OpenDetails(row?.keyID);
                                }}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                            <Tooltip
                                followCursor
                                placement="right-start"
                                enterDelay={1000}
                                leaveDelay={50}
                                title="Duplicate entitlement">
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    openDuplicateEntitlment(row.entitlements[0].entId);
                                }}>
                                    <ContentCopyIcon sx={{ fontSize: 15 }} fontSize="inherit" />
                                </IconButton>
                            </Tooltip>

                            {
                                (row.entitlements[0].entitlementItem[0]?.itemProduct[0]?.product.productName.toLowerCase().includes("DeviSOC".toLowerCase())) &&
                                <Tooltip
                                    followCursor
                                    placement="right-start"
                                    enterDelay={1000}
                                    leaveDelay={50}
                                    title="Go to GRAFANA : Licence activity in last 30 days"
                                >
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation();
                                        if (row?.keyID) window.open('https://grafana.kolibrx.com/d/ddryndy6ks7b4b/devisoc-dashboard-customerlicenseid?orgId=1&refresh=15m&from=now-30d&var-customerLicenseId=' + row?.keyID + '&var-customerCRMId=' + props.customerCrmId + '&var-customerName=' + props.customerName, '_blank');
                                    }}>
                                        <img src={`${process.env.PUBLIC_URL}/grafana_icon.svg`} alt="Grafana Icon" style={{
                                            width: 15,
                                            filter: (row?.keyID) ? '' : 'grayscale(100%)'
                                        }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }} scope="row">
                            {
                                row?.keyID?.split(",")?.map((k: any) => (
                                    <Box key={k} sx={{ color: row.entitlements[0].enabled ? 'text.primary' : 'text.disabled' }} >{k}</Box>
                                ))
                            }
                        </TableCell>
                        <TableCell sx={{ padding: '0px' }} >
                            <State disabled={!row.entitlements[0].enabled} entitlement={row.entitlements[0]} />
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ color: row.entitlements[0].enabled ? 'text.primary' : 'text.disabled' }}>
                                {format(new Date(row.entitlements[0].createDate), 'dd/MM/yyyy')}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ color: row.entitlements[0].enabled ? 'text.primary' : 'text.disabled' }}>
                                <Products key={row.entitlements[0].eid} entitlement={row.entitlements[0]} />
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            {
                                row.entitlements[0].description &&
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <div>
                                        <HtmlTooltip
                                            arrow
                                            placement="right"
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={handleTooltipClose}
                                            open={openTooltip}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={
                                                <React.Fragment>
                                                    {row.entitlements[0].description}
                                                </React.Fragment>
                                            }
                                        >
                                            <ChatOutlinedIcon style={{ color: '#a1a1a1', fontSize: 16 }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleTooltipOpen();
                                                }} />
                                        </HtmlTooltip>
                                    </div>
                                </ClickAwayListener>

                            }
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ color: row.entitlements[0].enabled ? 'text.primary' : 'text.disabled' }}>
                                {row.refId1}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ color: row.entitlements[0].enabled ? 'text.primary' : 'text.disabled' }}>
                                {getLICENSE_TYPE(row.entitlements[0])}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ color: row.entitlements[0].enabled ? 'text.primary' : 'text.disabled' }}>
                                {getEXPIRATION_DATE(row.entitlements[0])}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }} align="right">
                            <Box sx={{ color: row.entitlements[0].enabled ? 'text.primary' : 'text.disabled' }}>
                                {getCONCURRENT_INSTANCES(row.entitlements[0])}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }} colSpan={2}>
                            <Box sx={{
                                fontSize: 12,
                                whiteSpace: 'nowrap',
                                color: row.entitlements[0].enabled ? 'text.primary' : 'text.disabled'
                            }}>
                                <Link href={"mailto:" + row.entitlements[0].customerEmail + "?subject=[SOC Informatique] : Votre licence (" + row.entitlements[0].eid + ")" + "&body=Bonjour,"}
                                    underline="hover"
                                    target="_top"
                                    color="inherit">
                                    {row.entitlements[0].customerEmail}
                                </Link>
                            </Box>
                        </TableCell>
                    </TableRow>
                    {
                        row.entitlements &&
                        <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box sx={{ margin: 1, marginBottom: 8 }}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Key history
                                        </Typography>
                                        <SubRow row={row} />
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    }
                </React.Fragment>
            }
        </>
    );
}

function State(props: any) {

    const [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE, getLineItemType] = useEntitlementTools();

    useEffect(() => {
        if (props) { }
    }, [props]);

    return (
        <>
            <React.Fragment>
                <Tooltip
                    followCursor
                    placement="top"
                    title={getState(props?.entitlement)}>
                    <Box>
                        {
                            props?.entitlement?.entState === 0 && <DriveFileRenameOutlineOutlinedIcon style={{ color: '#0082B4', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 1 && <AccessTimeIcon style={{ color: '#20C997', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 2 && <KeyIcon style={{ color: '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 3 && <DoneAllIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 4 && <DoneIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 5 && <DoneAllIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState !== 5 &&
                            props?.entitlement?.entState !== 4 &&
                            props?.entitlement?.entState !== 3 &&
                            props?.entitlement?.entState !== 2 &&
                            props?.entitlement?.entState !== 1 &&
                            props?.entitlement?.entState !== 0 &&
                            <span>{props?.entitlement?.entState}</span>
                        }
                    </Box>
                </Tooltip>
            </React.Fragment>
        </>
    );
}

function Products(props: any) {
    return (
        <>
            {
                props?.entitlement?.entitlementItem[0]?.itemProduct?.map((k: any) => (
                    <Tooltip
                        key={k.product.productIdentifier}
                        followCursor
                        placement="top"
                        enterDelay={500}
                        leaveDelay={50}
                        title={<Features itemProduct={k}
                        />}>
                        <Box sx={{
                            '&:hover': { color: '#cc141e' },
                            'whiteSpace': 'nowrap'
                        }}>{k.product.productName}</Box>
                    </Tooltip>
                ))
            }
        </>
    );
}

function Features(props: any) {
    return (
        <>
            <Typography color="inherit">{props?.itemProduct?.product?.productName}</Typography>
            <ul>
                {
                    props?.itemProduct?.product?.productFeatureRef?.map((k: any) => (
                        <li key={k.featureId}>{k.featureName}</li>
                    ))
                }
            </ul>
        </>
    );
}

function SubRow(props: any) {
    const [getIds, secretKey] = useIds();
    const [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE, getLineItemType] = useEntitlementTools();
    const [entitlements, setEntitlements] = useState<any[]>();
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);

    const padding = '6px 5px';
    useEffect(() => {
        if (props) {
            const ids = getIds();
            let restAPI = new SocSentinelWebApi();

            let shuttleEntitlements: IEntitlementsShuttle = {
                authShuttle: { cryptedIds: ids.cryptedIds },
                keyID: props.row.keyID,
                entId: props.row.entitlements[0].entId
            }

            if (shuttleEntitlements.keyID) {
                restAPI.EntitlementsByKeyID(shuttleEntitlements)
                    .then((u) => { setEntitlements(u); })
                    .catch((a) => {
                        //debugger;
                        setAlertMessage(a);
                        setAlertOpen(true);
                    });
            }
            else {
                restAPI.EntitlementsByID(shuttleEntitlements)
                    .then((u) => { setEntitlements(u); })
                    .catch((a) => {
                        //debugger;
                        setAlertMessage(a);
                        setAlertOpen(true);
                    });
            }

        }
    }, [props]);

    const handleAlerteClose = () => { setAlertOpen(false); };

    return (
        <>
            <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
            <Table size="small" aria-label="purchases">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ padding: '0px' }} />
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                Created On
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                By
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                Product
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                RefID1
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                EID
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                Entitlement Type
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                License Type
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                Expiration date
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }} align="right">
                            <Box sx={{ fontSize: 12 }}>
                                Instances
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: { padding } }}>
                            <Box sx={{ fontSize: 12 }}>
                                Email
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        !entitlements &&
                        <>
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
                                    <Typography>
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </>
                    }
                    {
                        entitlements && entitlements.map((entitlement: any) => (
                            <TableRow hover key={entitlement.eid}>
                                <TableCell sx={{ padding: '0px' }}>
                                    <State disabled={!entitlement.enabled} fontSize={12} entitlement={entitlement} />
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        {format(new Date(entitlement.createDate), 'dd/MM/yyyy')}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        {entitlement.createdBy}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        <Products key={entitlement.eid} entitlement={entitlement} />
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        {entitlement.refId1}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        {entitlement.eid}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        {getLineItemType(entitlement)}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        {getLICENSE_TYPE(entitlement)}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        {getEXPIRATION_DATE(entitlement)}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }} align="right">
                                    <Box sx={{ fontSize: 12, color: entitlement.enabled ? 'text.primary' : 'text.disabled' }}>
                                        {getCONCURRENT_INSTANCES(entitlement)}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ padding: { padding } }}>
                                    <Box sx={{
                                        fontSize: 12,
                                        color: entitlement.enabled ? 'text.primary' : 'text.disabled',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {entitlement.customerEmail}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </>);
}