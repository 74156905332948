import * as React from 'react';
import { useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IAuthShuttle, SocSentinelWebApi } from '../../Services/soc-sentinel-web-api';
import { MessageBox } from '../Tools/MessageBox';
import { Backdrop, CircularProgress } from '@mui/material';
import useIds from '../../Services/idsHook';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.socinformatique.fr/">
                SOC Informatique
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme({
    palette: {
        primary: {
            light: '#d6434b',
            main: '#cc141e',
            dark: '#8e0e15',
            contrastText: '#fff',
        },
        secondary: {
            light: '#505357',
            main: '##25292e',
            dark: '#191c20',
            contrastText: '#000',
        },
    },
});

export async function loader(params: any) {
    return params['params'].contactId;
}

export default function SignIn() {
    const [getIds, secretKey] = useIds();
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        //debugger
        let CryptoJS = require("crypto-js");

        setOpenBackDrop(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let isRemberMeChecked = data.get('rememberMe') ? true : false;
        let login = data.get('login')?.toString();
        let password = data.get('password')?.toString();

        let restAPI = new SocSentinelWebApi();
        if (login && password) {
            let ids: IAuthShuttle = {
                cryptedIds: CryptoJS.AES.encrypt(JSON.stringify({
                    login: login,
                    password: password,
                    date: new Date()
                }), secretKey).toString(),
            }

            restAPI.Auth(ids).then((u) => {
                //console.log(u);

                let ids = {
                    login: login,
                    password: password,
                    date: new Date()
                }

                const encrypted = CryptoJS.AES.encrypt(JSON.stringify(ids), secretKey);
                let idsCrypto = encrypted.toString();
                // const keySizeInBytes = encrypted.key.sigBytes;
                // const keySizeInBits = keySizeInBytes * 8;
                //console.log('Key size:', keySizeInBits, 'bits');

                if (isRemberMeChecked) {
                    localStorage.setItem('ids', idsCrypto);
                } else {
                    sessionStorage.setItem('ids', idsCrypto);
                }

                window.location.reload();

            }).catch((a) => {
                //debugger;
                setAlertMessage(a);
                setAlertOpen(true);
                setOpenBackDrop(false);
            });
        }
        else {
            setOpenBackDrop(false);
        }

    };

    useEffect(() => {
    }, []);

    const handleAlerteClose = () => {
        setAlertOpen(false);
    };

    return (
        <>
            <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackDrop} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Login to the SOC license manager
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="login"
                                label="Login"
                                name="login"
                                autoComplete="login"
                                autoFocus
                            //defaultValue="admin"
                            //defaultValue="jmperi"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"

                            //defaultValue="2OEb8WiOyp5TF79mn0lc"
                            //defaultValue="z7AOILR6H7am4dirM0Nu"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox value="remember"
                                        color="primary"
                                        name="rememberMe"
                                        defaultChecked />}
                                label="Keep Connected"
                            />
                            <Button
                                type="submit"
                                fullWidth variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => { }}>
                                Login
                            </Button>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </ThemeProvider>
        </>
    );
}