import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import { ICustomerDetailsShuttle, IInstance, ISOC_listResponse, SocSentinelWebApi } from '../../Services/soc-sentinel-web-api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, TableContainer, TablePagination, TableSortLabel, useTheme, Stack, Tooltip } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { visuallyHidden } from '@mui/utils';
import useIds from '../../Services/idsHook';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { CustomerDialog } from './CustomerDialog';
import { MessageBox } from '../Tools/MessageBox';
import { debug } from 'console';

export interface IFilter {
  pagination: IPagination,
  sorting: ISorting
}

export interface IPagination {
  page: number,
  rowsPerPage: number
}

export interface ISorting {
  order?: Order,
  orderBy?: keyof Data
}

export interface CustomerListProps {
  instanceCustomers: ISOC_listResponse | undefined,
  onPaginationChange?: (pagination: IPagination) => void,
  onSortingChange?: (pagination: ISorting) => void,
  onFilterChange?: (filter: IFilter) => void,
  onListChange?: () => void,
  vendorId: number,
  defaultpageSize?: number,
  showAddCustomerAction: boolean
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => { onPageChange(event, 0); };
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => { onPageChange(event, page - 1); };
  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => { onPageChange(event, page + 1); };
  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => { onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1)); };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

interface Data {
  customerName: string;
  crmId: string;
  city: string;
  defaultContact: string;
  type: string
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'customerName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sortable: true
  },
  {
    id: 'crmId',
    numeric: false,
    disablePadding: false,
    label: 'CRM Id',
    sortable: true
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'City',
    sortable: false
  },
  {
    id: 'defaultContact',
    numeric: false,
    disablePadding: false,
    label: 'Default contact',
    sortable: false
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    sortable: false
  }
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order?: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {
              headCell.sortable &&
              <Tooltip
                followCursor
                placement="right-start"
                enterDelay={1000}
                leaveDelay={50}
                title={"Sort by " + headCell.label}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </Tooltip>
            }
            {!headCell.sortable && headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const CustomerList: React.FC<CustomerListProps> = (props) => {
  const [getIds, secretKey] = useIds();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [instancesCustomers, setInstancesCustomers] = useState<ISOC_listResponse>();
  //const [order, setOrder] = React.useState<Order>('asc');
  const [order, setOrder] = React.useState<Order>();
  const [orderBy, setOrderBy] = React.useState<keyof Data>();
  const [openModalCustomer, setOpenModalCustomer] = useState(false);

  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);

  const [rowsPerPageOptions, setRowsPerPageOptions] = useState<number[]>();

  function compareNumbers(a: number, b: number) {
    return a - b;
  }

  useEffect(() => {
    setInstancesCustomers(props.instanceCustomers)

    //debugger
    let tempRowsPerPageOptions: number[] = [10, 25, 50];
    if (props.instanceCustomers?.pageSize) {
      setRowsPerPage(props.instanceCustomers.pageSize);
      if (tempRowsPerPageOptions.indexOf(props.instanceCustomers.pageSize) === -1)
        tempRowsPerPageOptions.push(props.instanceCustomers.pageSize);
    }
    if (props.defaultpageSize) {
      if (tempRowsPerPageOptions.indexOf(+props.defaultpageSize) === -1)
        tempRowsPerPageOptions.push(+props.defaultpageSize);
    }
    setRowsPerPageOptions(tempRowsPerPageOptions.sort(compareNumbers));

    if (props.instanceCustomers?.pageIndex)
      setPage(props.instanceCustomers.pageIndex - 1);

    if (props.instanceCustomers?.sortOrder) {
      const isAsc = props.instanceCustomers.sortOrder === 'asc';
      setOrder(isAsc ? 'asc' : 'desc');
    }

    if (props.instanceCustomers?.sortCol) {
      let property: keyof Data;
      switch (props.instanceCustomers.sortCol) {
        case "customerName": {
          property = "customerName";
          setOrderBy(property);
          break;
        }
        case "type": {
          property = "type";
          setOrderBy(property);
          break;
        }
        case "crmId": {
          property = "crmId";
          setOrderBy(property);
          break;
        }
        default: {
          break;
        }
      }
    }

    if (props.instanceCustomers?.instances)
      props.instanceCustomers.instances.forEach(instance => {
        const ids = getIds();
        let restAPI = new SocSentinelWebApi();

        if (instance.customerId) {
          let shuttle: ICustomerDetailsShuttle = {
            authShuttle: { cryptedIds: ids.cryptedIds },
            customerId: instance.customerId,
          };

          restAPI.CustomerDetails(shuttle)
            .then((u) => {
              const cloneFood = Object.assign({}, props.instanceCustomers);
              var modele = cloneFood.instances?.find((e) => { return e.customerRefId == u.customerRefId; });
              if (modele) modele.crmId = u.crmId;
              setInstancesCustomers(cloneFood)
            }).catch((a) => {
              //debugger;
            });
        }
      });


  }, [props.instanceCustomers]);

  const handleClick = (event: React.MouseEvent<unknown>, customerId: string | undefined) => {
    navigate({ pathname: '/sentinel/customer/' + customerId });
  };

  const handleChangePage = (event: unknown, newPage: number) => {

    //debugger
    console.log("handleChangePage");

    setPage(newPage);
    if (props.onPaginationChange) {
      props.onPaginationChange({
        page: newPage + 1,
        rowsPerPage: rowsPerPage
      });
    }
    if (props.onFilterChange) {
      props.onFilterChange({
        pagination: {
          page: newPage + 1,
          rowsPerPage: rowsPerPage
        },
        sorting: {
          order: order,
          orderBy: orderBy
        }
      });
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {

    console.log("handleChangeRowsPerPage");
    //debugger
    setPage(0);

    if (props.onPaginationChange) {
      props.onPaginationChange({
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      });
    }

    if (props.onFilterChange) {
      props.onFilterChange({
        pagination: {
          page: 0,
          rowsPerPage: parseInt(event.target.value, 10)
        },
        sorting: {
          order: order,
          orderBy: orderBy
        }
      });
    }
  };


  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {

    //debugger
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    if (props.onSortingChange) {
      props.onSortingChange({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property
      });
    }

    if (props.onFilterChange) {
      props.onFilterChange({
        pagination: {
          page: 0,
          rowsPerPage: rowsPerPage
        },
        sorting: {
          order: isAsc ? 'desc' : 'asc',
          orderBy: property
        }
      });
    }

  };

  const openAddCustomer = (a: IInstance | undefined) => { setOpenModalCustomer(true); };
  const handleAlerteClose = () => { setAlertOpen(false); };

  return (
    <>
      <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
      {
        <CustomerDialog
          vendorId={props.vendorId}
          open={openModalCustomer}
          onClose={(e) => {
            setOpenModalCustomer(false);
            if (e.dataCreatedOrUpdated) {
              if (props.onListChange) {
                props.onListChange();
              }
            }
          }} />
      }
      {
        <React.Fragment>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0.5}>
                {
                  props.showAddCustomerAction &&
                  <Tooltip
                    followCursor
                    placement="right-start"
                    enterDelay={1000}
                    leaveDelay={50}
                    title="Add customer">
                    <IconButton onClick={(e) => {
                      openAddCustomer(undefined);
                    }}>
                      <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>}
                <Title>Customers</Title>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={instancesCustomers?.totalCount ? instancesCustomers?.totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page', },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                {
                  instancesCustomers &&
                  <Table size="small">
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy ? orderBy : ''}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {instancesCustomers.instances?.map((instance) => (

                        <TableRow
                          hover
                          key={instance.customerId}
                        >
                          <TableCell><Link href={'/sentinel/customer/' + instance.customerId} style={{ textDecoration: 'none' }}>{instance.name}</Link></TableCell>
                          <TableCell>{instance.crmId}</TableCell>
                          <TableCell>{instance.billingCity?.toUpperCase()}</TableCell>
                          <TableCell>{instance.defaultContactName}</TableCell>
                          <TableCell>{instance.type}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                }
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={instancesCustomers?.totalCount ? instancesCustomers?.totalCount : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </React.Fragment>
      }
    </>
  );
}