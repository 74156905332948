import React, { MutableRefObject, useEffect, useImperativeHandle, useState } from "react";
import useIds from "../../Services/idsHook";
import Title from "../Title";
import { Backdrop, Box, Button, CircularProgress, ClickAwayListener, Grid, IconButton, Menu, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Stack, SvgIcon, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { CustomerType, IAuthShuttle, ICustomerDetailsShuttle, ISOC_listResponse, SocSentinelWebApi } from "../../Services/soc-sentinel-web-api";
import { useNavigate } from "react-router-dom";
import { MessageBox } from "../Tools/MessageBox";
import { CustomerSearchForm } from "./CustomerSearchForm";


export interface CustomerDetailsProps {
    vendorId: number,
    crmId?: string,
    customerId?: string,
    isInModal?: boolean,
    random?: number
    onCustomerCreated?: (customerDetails: any) => void;
    onCustomerDetailsLoaded?: (customerDetails: any) => void;
    onCustomerUpdated?: (customerDetails: any) => void;
    onCustomerDetailsChange?: (customerDetails: any) => void;
    childRef?: MutableRefObject<HTMLDivElement | null>;
}

export interface CustomerDetailsRef {
    triggerAction: () => void;
}

export const CustomerDetails = React.forwardRef<CustomerDetailsRef, CustomerDetailsProps>((props, ref) => {

    const triggerAction = () => { Save(); };
    useImperativeHandle(ref, () => ({ triggerAction, }));

    const [getIds, secretKey] = useIds();
    const [customerDetails, setCustomerDetails] = useState<any | undefined>(undefined);
    const [refreshCustomerDetails, setRefreshCustomerDetails] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [editable, setEditable] = useState<boolean>(false);
    const [nameError, setNameError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailIdError, setEmailIdError] = useState(false);
    const [authShuttle, setAuthShuttle] = useState<IAuthShuttle | undefined>(undefined);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (customerDetails.crmId) setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let ids = getIds();

        let tempAuthShuttle: IAuthShuttle = { cryptedIds: ids.cryptedIds }
        setAuthShuttle(tempAuthShuttle);

        if (props.customerId || props.crmId) {
            laodCustomerDetails(tempAuthShuttle, props.customerId, props.crmId);
        }
        else {
            let customerDetails = {
                type: 0,
                crmId: "",
                name: "",
                defaultContact: { firstName: "", lastName: "", emailId: "" },
                addresses: {
                    address: [{
                        city: "",
                        country: "",
                        state: "",
                        street: "",
                        zip: ""
                    }]
                }
            };
            setCustDetails(customerDetails);
            setEditable(true);
        }

    }, [props.crmId, props.customerId, props.random]);

    const setCustDetails = (customerDetails: any) => {
        setCustomerDetails(customerDetails);
        if (props.onCustomerDetailsChange) props.onCustomerDetailsChange(customerDetails);
    }

    const laodCustomerDetails = async (authShuttle: IAuthShuttle, customerId?: string, crmId?: string) => {
        let restAPI = new SocSentinelWebApi();
        let shuttleDetails: ICustomerDetailsShuttle = {
            authShuttle: authShuttle,
            customerId: customerId,
            crmId: crmId
        }

        if (customerId || crmId) {
            setRefreshCustomerDetails(true);
            try {

                let CustDetails = await restAPI.CustomerDetails(shuttleDetails);
                if (CustDetails)
                    if (!CustDetails.customerId) {
                        let custId = await restAPI.CustomerGetID(shuttleDetails);
                        CustDetails.customerId = custId.toString();
                    }

                setCustDetails(CustDetails);
                setRefreshCustomerDetails(false);
                if (props.onCustomerDetailsLoaded) {
                    props.onCustomerDetailsLoaded(CustDetails);
                }

                if (CustDetails.type === 0) {
                    document.title = "Customer - " + CustDetails.name;
                    setName(CustDetails.name);
                }
                else if (CustDetails.type === 1) {
                    let tempName = CustDetails.defaultContact.firstName + " " + CustDetails.defaultContact.lastName;
                    document.title = "Customer - " + tempName;
                    setName(tempName);
                }

            } catch (ex: any) {
                //debugger
                setAlertMessage(ex);
                setAlertOpen(true);
                setOpenBackDrop(false);
            }
        }
    }

    const Save = () => {
        //debugger
        let restAPI = new SocSentinelWebApi();
        if (customerDetails) {

            let error = false;
            if (customerDetails.type === 1) {

                if (!customerDetails.defaultContact.firstName || customerDetails.defaultContact.firstName === "") {
                    error = true;
                    setFirstNameError(true);
                }

                if (!customerDetails.defaultContact.lastName || customerDetails.defaultContact.lastName === "") {
                    error = true;
                    setLastNameError(true);
                }

                if (!customerDetails.defaultContact.emailId || customerDetails.defaultContact.emailId === "") {
                    error = true;
                    setEmailIdError(true);
                }
                else {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(customerDetails.defaultContact.emailId)) {
                        error = true;
                        setEmailIdError(true);
                    }
                }
            }
            else {
                if (!customerDetails.name || customerDetails.name === "") {
                    error = true;
                    setNameError(true);
                }
            }

            if (!error && authShuttle) {

                setOpenBackDrop(true);
                let customerDetailsShuttle: ICustomerDetailsShuttle = {
                    authShuttle: authShuttle,
                    vendorId: props.vendorId,
                    customerId: customerDetails.customerId,
                    crmId: customerDetails.crmId,
                    name: customerDetails.name,
                    firstName: customerDetails.defaultContact?.firstName,
                    lastName: customerDetails.defaultContact?.lastName,
                    email: customerDetails.defaultContact?.emailId,
                    city: customerDetails.addresses.address[0].city,
                    country: customerDetails.addresses.address[0].country,
                    state: customerDetails.addresses.address[0].state,
                    street: customerDetails.addresses.address[0].street,
                    zip: customerDetails.addresses.address[0].zip,
                    type: customerDetails.type
                }

                if (customerDetails.customerId) {
                    restAPI.CustomerUpdate(customerDetailsShuttle).then((u) => {
                        if (props.onCustomerUpdated) {
                            props.onCustomerUpdated(u);
                        }
                        laodCustomerDetails(authShuttle, props.customerId, props.crmId);
                        setEditable(false);
                        setOpenBackDrop(false);
                    }).catch((a) => {
                        //debugger;
                        setAlertMessage(a);
                        setAlertOpen(true);
                        setOpenBackDrop(false);
                    });
                }
                else {
                    restAPI.CustomerCreate(customerDetailsShuttle).then((u) => {
                        if (props.onCustomerCreated) {
                            props.onCustomerCreated(u);
                        }
                        laodCustomerDetails(authShuttle, props.customerId, props.crmId);
                        setOpenBackDrop(false);
                    }).catch((a) => {
                        //debugger;
                        setAlertMessage(a);
                        setAlertOpen(true);
                        setOpenBackDrop(false);
                    });
                }
            }
        }

    };

    const navigate = useNavigate();
    const [openTooltip, setOpenTooltip] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);

    const deleteCustomer = (e: any) => {
        let restAPI = new SocSentinelWebApi();
        if (customerDetails && authShuttle) {
            if (customerDetails.customerId) {
                setOpenBackDrop(true);
                let customerDetailsShuttle: ICustomerDetailsShuttle = {
                    authShuttle: authShuttle,
                    vendorId: props.vendorId,
                    customerId: customerDetails.customerId,
                }
                setOpenTooltip(false);
                restAPI.CustomerDelete(customerDetailsShuttle).then((r) => {
                    navigate({ pathname: '/sentinel/customer' });
                }).catch((a) => {
                    //debugger
                    setAlertMessage(a);
                    setAlertOpen(true);
                    setOpenBackDrop(false);
                });
            }
        }
    };

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgb(255 255 255 / 0%)',
            width: 1000,
        },
    }));

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    const handleAlerteClose = () => {
        setAlertOpen(false);
    };

    return (
        <React.Fragment>
            <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
            {
                <Grid container spacing={2}>
                    {
                        !props.isInModal &&
                        <Grid item xs={11}>
                            <Title>Customer details</Title>
                        </Grid>
                    }
                    <Grid item xs={1}>
                        {
                            !props.isInModal &&
                            <>
                                {
                                    !editable &&
                                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                        <Button size="small" onClick={() => { setEditable(true); }} >Edit</Button>
                                        <Box>
                                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                                <div>
                                                    <HtmlTooltip
                                                        arrow
                                                        placement="top-end"
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        onClose={handleTooltipClose}
                                                        open={openTooltip}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title={
                                                            <React.Fragment>
                                                                <Box sx={{
                                                                    textAlign: "center"
                                                                }}>
                                                                    <Paper elevation={24}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12}>

                                                                                <Typography variant="h6">
                                                                                    Delete customer
                                                                                </Typography>

                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="body1">
                                                                                    Are you sure ?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Button onClick={handleTooltipClose} autoFocus>No</Button>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Button onClick={deleteCustomer}>Yes</Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Paper>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Button size="small" onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleTooltipOpen();
                                                        }} >Delete</Button>
                                                    </HtmlTooltip>

                                                </div>
                                            </ClickAwayListener>
                                        </Box>
                                    </Stack>
                                }
                                {
                                    editable &&
                                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                        <Button size="small" onClick={() => {
                                            setEditable(false);
                                            if (authShuttle) laodCustomerDetails(authShuttle, props.customerId, props.crmId);
                                        }}>Cancel</Button>
                                        <Button size="small" onClick={(e) => { Save(); }}>Save</Button>
                                    </Stack>
                                }
                            </>
                        }
                    </Grid>
                </Grid>
            }
            {
                ((!customerDetails) || refreshCustomerDetails) &&
                <React.Fragment>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </React.Fragment>
            }
            {
                customerDetails && !refreshCustomerDetails &&
                <Grid container spacing={1} rowSpacing={2}>
                    {
                        props.isInModal &&
                        <Grid item xs={12}>
                            <Select
                                error={false}
                                labelId="lbl-type"
                                id="cbo-type"
                                value={customerDetails.type}
                                label="Type"
                                autoWidth
                                onChange={(event: SelectChangeEvent) => {
                                    const tempCustomerDetails = Object.assign({}, customerDetails);
                                    tempCustomerDetails.type = +event.target.value;
                                    setCustDetails(tempCustomerDetails);
                                    console.log(tempCustomerDetails);
                                }}
                            >
                                <MenuItem value={CustomerType.ind}>Individual</MenuItem>
                                <MenuItem value={CustomerType.org}>Company</MenuItem>
                            </Select>
                        </Grid>}
                    {
                        customerDetails.type === 0 &&
                        <Grid item xs={12} sm={!props.isInModal ? 12 : 12}>
                            <TextField
                                required
                                error={nameError}
                                helperText={nameError ? "Missing" : ""}
                                id="name"
                                name="name"
                                label="Name"
                                fullWidth
                                autoComplete="name"
                                variant={!editable ? "standard" : "outlined"}
                                value={name || ''}
                                inputProps={{ readOnly: !editable, }}
                                onChange={(event: any) => {
                                    setNameError(false);
                                    const tempCustomerDetails = Object.assign({}, customerDetails);
                                    tempCustomerDetails.name = event.target.value;
                                    setName(tempCustomerDetails.name);
                                    setCustDetails(tempCustomerDetails);
                                    //console.log(tempCustomerDetails);
                                }}
                            />
                        </Grid>
                    }
                    {
                        customerDetails.type === 1 &&
                        <>
                            <Grid item xs={12} sm={!props.isInModal ? 4 : 4}>
                                <TextField
                                    required
                                    error={firstNameError}
                                    helperText={firstNameError ? "Missing" : ""}
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    fullWidth
                                    autoComplete="firstName"
                                    variant={!editable ? "standard" : "outlined"}
                                    value={customerDetails.defaultContact.firstName || ''}
                                    inputProps={{ readOnly: !editable, }}
                                    onChange={(event: any) => {
                                        setFirstNameError(false);
                                        const tempCustomerDetails = Object.assign({}, customerDetails);
                                        tempCustomerDetails.defaultContact.firstName = event.target.value;
                                        setName(tempCustomerDetails.defaultContact.firstName + " " + tempCustomerDetails.defaultContact.lastName);
                                        setCustDetails(tempCustomerDetails);
                                        console.log(tempCustomerDetails);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={!props.isInModal ? 4 : 4}>
                                <TextField
                                    required
                                    error={lastNameError}
                                    helperText={lastNameError ? "Missing" : ""}
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    fullWidth
                                    autoComplete="lastName"
                                    variant={!editable ? "standard" : "outlined"}
                                    value={customerDetails.defaultContact.lastName || ''}
                                    inputProps={{ readOnly: !editable, }}
                                    onChange={(event: any) => {
                                        setLastNameError(false);
                                        const tempCustomerDetails = Object.assign({}, customerDetails);
                                        tempCustomerDetails.defaultContact.lastName = event.target.value;
                                        setName(tempCustomerDetails.defaultContact.firstName + " " + tempCustomerDetails.defaultContact.lastName);
                                        setCustDetails(tempCustomerDetails);
                                        console.log(tempCustomerDetails);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={!props.isInModal ? 4 : 4}>
                                <TextField
                                    required
                                    error={emailIdError}
                                    helperText={emailIdError ? "Missing or wrong" : ""}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    autoComplete="Email"
                                    variant={!editable ? "standard" : "outlined"}
                                    value={customerDetails.defaultContact.emailId || ''}
                                    inputProps={{ readOnly: !editable, }}
                                    onChange={(event: any) => {
                                        setLastNameError(false);
                                        const tempCustomerDetails = Object.assign({}, customerDetails);
                                        tempCustomerDetails.defaultContact.emailId = event.target.value;
                                        setCustDetails(tempCustomerDetails);
                                        console.log(tempCustomerDetails);
                                    }}
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} sm={!props.isInModal ? 6 : 12} >
                        <Grid container spacing={2}
                        //sm={!props.isInModal ? 6 : 12}
                        >
                            {
                                !props.isInModal &&
                                <Grid item
                                    //xs={12} 
                                    sm={1}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '20px' }}
                                >

                                    <Tooltip
                                        followCursor
                                        placement="right-start"
                                        enterDelay={1000}
                                        leaveDelay={50}
                                        title="Go to GRAFANA : Customer activity in last 30 days">
                                        <IconButton onClick={handleClick}>
                                            <img src={`${process.env.PUBLIC_URL}/grafana_icon.svg`} alt="Grafana Icon" style={{
                                                width: 24,
                                                height: 24,
                                                filter: customerDetails.crmId ? '' : 'grayscale(100%)'
                                            }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem
                                            title="Go to GRAFANA : Customer activity in last 30 days in DeviSOC"
                                            onClick={(e) => {
                                                setAnchorEl(null);
                                                window.open('https://grafana.kolibrx.com/d/DdwSbD2Il/devisoc-dashboard-customerid?orgId=1&refresh=15m&from=now-30d&to=now&var-customerCRMId=' + customerDetails.crmId + '&var-customerName=' + name, '_blank');
                                            }}>
                                            <img src={`${process.env.PUBLIC_URL}/devisoc-logiciel-de-metres.png`} alt="Grafana Icon" style={{ marginRight: '5px', width: 20/*, filter: 'grayscale(100%)'*/ }} />
                                            <Typography variant="body1" color="text.secondary">
                                                DeviSOC
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem
                                            title="Go to GRAFANA : Customer activity in last 30 days in JustBIM"
                                            onClick={(e) => {
                                                setAnchorEl(null);
                                                //window.open('https://grafana.kolibrx.com/d/DdwSbD2Il/devisoc-dashboard-customerid?orgId=1&refresh=15m&from=now-30d&to=now&var-customerCRMId=' + customerDetails.crmId + '&var-customerName=' + name, '_blank');
                                            }}
                                            style={{ filter: 'grayscale(100%)' }}>
                                            <img src={`${process.env.PUBLIC_URL}/justbim.png`} alt="Grafana Icon" style={{ marginRight: '5px', width: 20 }} />
                                            <Typography variant="body1" color="text.secondary">
                                                JustBIM
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem
                                            title="Go to GRAFANA : Customer activity in last 30 days in BIMQuantify"
                                            onClick={(e) => {
                                                setAnchorEl(null);
                                                //window.open('https://grafana.kolibrx.com/d/DdwSbD2Il/devisoc-dashboard-customerid?orgId=1&refresh=15m&from=now-30d&to=now&var-customerCRMId=' + customerDetails.crmId + '&var-customerName=' + name, '_blank');
                                            }}
                                            style={{ filter: 'grayscale(100%)' }}>
                                            <img src={`${process.env.PUBLIC_URL}/bim-c.png`} alt="Grafana Icon" style={{ marginRight: '5px', width: 20 }} />
                                            <Typography variant="body1" color="text.secondary">
                                                BIMQuantify
                                            </Typography>
                                        </MenuItem>
                                    </Menu>

                                </Grid>
                            }
                            <Grid item
                                //xs={12}
                                //sm={!props.isInModal ? 5 : 12}
                                sm={!props.isInModal ? 11 : 12}
                            >
                                <TextField
                                    //required
                                    id="crmId"
                                    name="crmId"
                                    label="CRM Id"
                                    fullWidth
                                    autoComplete="crmId"
                                    variant={!editable ? "standard" : "outlined"}
                                    value={customerDetails.crmId || ''}
                                    inputProps={{ readOnly: !editable, }}
                                    onChange={(event: any) => {
                                        const tempCustomerDetails = Object.assign({}, customerDetails);
                                        tempCustomerDetails.crmId = event.target.value;
                                        setCustDetails(tempCustomerDetails);
                                        //console.log(tempCustomerDetails);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        !props.isInModal &&
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={!editable ? false : true}
                                id="customerRefId"
                                name="customerRefId"
                                label="Ref Id"
                                fullWidth
                                autoComplete="customerRefId"
                                variant={!editable ? "standard" : "outlined"}
                                value={customerDetails.customerRefId}
                                inputProps={{ readOnly: true, }}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            id="street"
                            name="street"
                            label="Street"
                            fullWidth
                            autoComplete="street"
                            variant={!editable ? "standard" : "outlined"}
                            value={customerDetails?.addresses ? customerDetails.addresses.address[0].street : ""}
                            inputProps={{ readOnly: !editable, }}
                            onChange={(event: any) => {
                                const tempCustomerDetails = Object.assign({}, customerDetails);
                                tempCustomerDetails.addresses.address[0].street = event.target.value;
                                setCustDetails(tempCustomerDetails);
                                //console.log(tempCustomerDetails);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="city"
                            name="city"
                            label="City"
                            fullWidth
                            autoComplete="city"
                            variant={!editable ? "standard" : "outlined"}
                            value={customerDetails?.addresses ? customerDetails.addresses.address[0].city : ""}
                            inputProps={{ readOnly: !editable, }}
                            onChange={(event: any) => {
                                const tempCustomerDetails = Object.assign({}, customerDetails);
                                tempCustomerDetails.addresses.address[0].city = event.target.value;
                                setCustDetails(tempCustomerDetails);
                                //console.log(tempCustomerDetails);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="state"
                            name="state"
                            label="State/Province/Region"
                            fullWidth
                            variant={!editable ? "standard" : "outlined"}
                            value={customerDetails?.addresses ? customerDetails.addresses.address[0].state : ""}
                            inputProps={{ readOnly: !editable, }}
                            onChange={(event: any) => {
                                const tempCustomerDetails = Object.assign({}, customerDetails);
                                tempCustomerDetails.addresses.address[0].state = event.target.value;
                                setCustDetails(tempCustomerDetails);
                                //console.log(tempCustomerDetails);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="zip"
                            name="zip"
                            label="Zip / Postal code"
                            fullWidth
                            autoComplete="shipping postal-code"
                            variant={!editable ? "standard" : "outlined"}
                            value={customerDetails?.addresses ? customerDetails.addresses.address[0].zip : ""}
                            inputProps={{ readOnly: !editable, }}
                            onChange={(event: any) => {
                                const tempCustomerDetails = Object.assign({}, customerDetails);
                                tempCustomerDetails.addresses.address[0].zip = event.target.value;
                                setCustDetails(tempCustomerDetails);
                                //console.log(tempCustomerDetails);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="country"
                            name="country"
                            label="Country"
                            fullWidth
                            autoComplete="shipping country"
                            variant={!editable ? "standard" : "outlined"}
                            value={customerDetails?.addresses ? customerDetails.addresses.address[0].country : ""}
                            inputProps={{ readOnly: !editable, }}
                            onChange={(event: any) => {
                                const tempCustomerDetails = Object.assign({}, customerDetails);
                                tempCustomerDetails.addresses.address[0].country = event.target.value;
                                setCustDetails(tempCustomerDetails);
                                //console.log(tempCustomerDetails);
                            }}
                        />
                    </Grid>
                </Grid>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment >
    );
});