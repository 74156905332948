import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { Tooltip } from '@mui/material';

export function MeunTopList() {
    const navigate = useNavigate();
    const GoToSentinelCustomers = () => navigate('/sentinel/customer');
    const GoToSentinelEMS = () => { window.open('https://socinformatique.sentinelcloud.com/ems', '_blank'); }

    return (
        <>
            <Tooltip
                followCursor
                placement="right-start"
                enterDelay={1000}
                leaveDelay={50}
                title="Customers">
                <ListItemButton onClick={GoToSentinelCustomers}>
                    <ListItemIcon>
                        <Diversity3Icon />
                    </ListItemIcon>
                    <ListItemText primary="Customers" />
                </ListItemButton>
            </Tooltip>
            <Tooltip
                followCursor
                placement="right-start"
                enterDelay={1000}
                leaveDelay={50}
                title="Sentinel EMS">
                <ListItemButton onClick={GoToSentinelEMS}>
                    <ListItemIcon>
                        <LocalPoliceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sentinel EMS" />
                </ListItemButton>
            </Tooltip>
        </>
    );
}

export function MeunBottomList() {
    const navigate = useNavigate();
    const GoToLogout = () => {
        localStorage.removeItem('ids');
        sessionStorage.removeItem('ids');
        navigate('/');
        window.location.reload();
    };

    return (
        <>
            <Tooltip
                followCursor
                placement="right-start"
                enterDelay={1000}
                leaveDelay={50}
                title="Logout">
                <ListItemButton onClick={GoToLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </Tooltip>
        </>
    );
}