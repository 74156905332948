import { useEffect, useRef, useState } from "react";
import useIds from "../../Services/idsHook";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { CustomerDetails, CustomerDetailsRef } from "./CustomerDetails";

export interface ICustomerDialog {
    dataCreatedOrUpdated: boolean
}

export interface ICustomerDialogProps {
    open: boolean,
    onClose?: (customerDialog: ICustomerDialog) => void;
    vendorId: number,
}

export const CustomerDialog: React.FC<ICustomerDialogProps> = (props) => {
    const childRef = useRef<CustomerDetailsRef>(null);
    const [getIds, secretKey] = useIds();
    const [openModal, setOpenModal] = useState(false);
    const [customerDetails, setCustomerDetails] = useState<any | undefined>(undefined);

    useEffect(() => {
        setOpenModal(props.open);
    }, [props]);

    const close = (dataCreatedOrUpdated:boolean) => {
        setOpenModal(false);
        if (props.onClose) {
            props.onClose({ dataCreatedOrUpdated: dataCreatedOrUpdated });
        }
    };

    return (<>
        <Dialog open={openModal} onClose={(e: any) => {
            close(false);
        }}>
            <DialogTitle>Customer</DialogTitle>
            <DialogContent>
                <CustomerDetails
                    ref={childRef}
                    isInModal={true}
                    vendorId={props.vendorId}
                    onCustomerDetailsLoaded={(r) => { }}
                    onCustomerUpdated={(r) => { }}
                    onCustomerDetailsChange={(r) => { setCustomerDetails(r); }}
                    onCustomerCreated={(r) => { close(true); }}
                />
            </DialogContent>
            <DialogActions>
                <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={2}>
                        <Button onClick={(e: any) => { close(false); }}>Cancel</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={() => { childRef?.current?.triggerAction(); }}>Save</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog >
    </>);
}
