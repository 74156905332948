import { Box, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Paper, Select, SelectChangeEvent, styled, Switch, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useIds from "../../Services/idsHook";
import { IAuthShuttle, IContactShuttle, IInstance, SocSentinelWebApi } from "../../Services/soc-sentinel-web-api";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React from "react";
import { MessageBox } from "../Tools/MessageBox";

export interface IContactDialogProps {
    customerId: number,
    open: boolean,
    onClose?: (contactDialog: IContactDialog) => void;
    vendorId: number,
    contactInstance?: IInstance
}

export enum Locale {
    ENGLISH = "en",
    FRENCH = "fr",
    DEUTSCH = "de"
}

export interface IContactDialog {
    dataCreatedOrOpdated: boolean
}

export const ContactDialog: React.FC<IContactDialogProps> = (props) => {
    const [getIds, secretKey] = useIds();
    const [openModal, setOpenModal] = useState(false);
    const [localeAction, setLocaleAction] = useState(Locale.FRENCH.toString());
    const [authShuttle, setAuthShuttle] = useState<IAuthShuttle | undefined>(undefined);
    const [isDefaultContact, setIsDefaultContact] = useState<boolean>(false);
    const [openTooltip, setOpenTooltip] = React.useState(false);
    const [contactCreateShuttle, setContactCreateShuttle] = useState<IContactShuttle>({
        authShuttle: null,
        vendorId: props.vendorId,
        customerId: props.customerId,
        emailId: "",
        firstName: "",
        lastName: "",
        locale: "fr",
        defaultField: false
    });
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);

    useEffect(() => {
        let ids = getIds();
        setOpenModal(props.open);

        let tempAuthShuttle: IAuthShuttle = {
            cryptedIds: ids.cryptedIds,
        }
        setAuthShuttle(tempAuthShuttle);

        let newContactCreateShuttle: IContactShuttle = {
            authShuttle: tempAuthShuttle,
            vendorId: props.vendorId,
            customerId: props.customerId,
            emailId: "",
            firstName: "",
            lastName: "",
            locale: "fr",
            defaultField: false
        }

        if (props.contactInstance?.contactId) {
            newContactCreateShuttle.emailId = props.contactInstance.emailId;
            newContactCreateShuttle.firstName = props.contactInstance.firstName;
            newContactCreateShuttle.lastName = props.contactInstance.lastName;
            newContactCreateShuttle.locale = props.contactInstance.locale;
            newContactCreateShuttle.contactId = props.contactInstance.contactId;
            newContactCreateShuttle.defaultField = props.contactInstance.default;
            setIsDefaultContact((props.contactInstance.default) ? props.contactInstance.default : false);
        }

        setContactCreateShuttle(newContactCreateShuttle);

    }, [props]);

    const handleClose = (e: any) => {
        setOpenModal(false);
        if (props.onClose) {
            props.onClose({ dataCreatedOrOpdated: false });
        }
    };

    const deleteContact = (e: any) => {
        let restAPI = new SocSentinelWebApi();
        if (contactCreateShuttle) {
            if (contactCreateShuttle.contactId) {
                restAPI.ContactDelete(contactCreateShuttle).then((r) => {
                    setOpenTooltip(false);
                    setOpenModal(false);
                    if (props.onClose) {
                        props.onClose({ dataCreatedOrOpdated: true });
                    }
                }).catch((a) => {
                    //debugger;
                    setAlertMessage(a);
                    setAlertOpen(true);
                });
            }
        }
    };

    const handleSave = (e: any) => {
        let restAPI = new SocSentinelWebApi();
        if (contactCreateShuttle) {

            let error = false;
            if (!contactCreateShuttle.firstName || contactCreateShuttle.firstName === "") {
                error = true;
                setFirstNameError(true);
            }

            if (!contactCreateShuttle.lastName || contactCreateShuttle.lastName === "") {
                error = true;
                setLastNameError(true);
            }

            if (!contactCreateShuttle.emailId || contactCreateShuttle.emailId === "") {
                error = true;
                setEmailError(true);
            }
            else {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(contactCreateShuttle.emailId)) {
                    error = true;
                    setEmailError(true);
                }
            }

            if (!error) {
                if (contactCreateShuttle.contactId) {
                    restAPI.ContactUpdate(contactCreateShuttle).then((r) => {
                        setOpenModal(false);
                        if (props.onClose) {
                            props.onClose({ dataCreatedOrOpdated: true });
                        }
                    }).catch((a) => {
                        //debugger;
                        setAlertMessage(a);
                        setAlertOpen(true);
                    });
                }
                else {
                    restAPI.ContactCreate(contactCreateShuttle).then((r) => {
                        setOpenModal(false);
                        if (props.onClose) {
                            props.onClose({ dataCreatedOrOpdated: true });
                        }
                    }).catch((a) => {
                        //debugger;
                        setAlertMessage(a);
                        setAlertOpen(true);
                    });
                }
            }
        }

    };

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgb(255 255 255 / 0%)',
            width: 1000,
        },
    }));

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    const handleAlerteClose = () => {
        setAlertOpen(false);
    };

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    return (<>
        <Dialog open={openModal} onClose={handleClose}>
            <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
            <DialogTitle>Contact</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            error={firstNameError}
                            helperText={firstNameError ? "Missing" : ""}
                            required
                            autoFocus
                            margin="dense"
                            id="firstName"
                            label="First Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={contactCreateShuttle.firstName || ''}
                            onChange={(event: any) => {
                                setFirstNameError(false);
                                const newContactCreateShuttle = Object.assign({}, contactCreateShuttle);
                                newContactCreateShuttle.firstName = event.target.value;
                                setContactCreateShuttle(newContactCreateShuttle);
                                console.log(newContactCreateShuttle);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={lastNameError}
                            helperText={lastNameError ? "Missing" : ""}
                            required
                            margin="dense"
                            id="lastName"
                            label="Last Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={contactCreateShuttle.lastName || ''}
                            onChange={(event: any) => {
                                setLastNameError(false);
                                const newContactCreateShuttle = Object.assign({}, contactCreateShuttle);
                                newContactCreateShuttle.lastName = event.target.value;
                                setContactCreateShuttle(newContactCreateShuttle);
                                console.log(newContactCreateShuttle);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            error={emailError}
                            helperText={emailError ? "Missing or wrong" : ""}
                            margin="dense"
                            id="email"
                            label="E-mail"
                            type="email"
                            fullWidth
                            variant="outlined"
                            value={contactCreateShuttle.emailId || ''}
                            onChange={(event: any) => {
                                setEmailError(false);
                                const newContactCreateShuttle = Object.assign({}, contactCreateShuttle);
                                newContactCreateShuttle.emailId = event.target.value;
                                setContactCreateShuttle(newContactCreateShuttle);
                                console.log(newContactCreateShuttle);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            error={false}
                            labelId="lbl-locale"
                            id="cbo-locale"
                            value={contactCreateShuttle.locale || 'fr'}
                            label="Locale"
                            autoWidth
                            onChange={(event: SelectChangeEvent) => {
                                setLocaleAction(event.target.value);
                                const newContactCreateShuttle = Object.assign({}, contactCreateShuttle);
                                newContactCreateShuttle.locale = event.target.value;
                                setContactCreateShuttle(newContactCreateShuttle);
                                console.log(newContactCreateShuttle);
                            }}
                        >
                            <MenuItem value={Locale.ENGLISH}>English</MenuItem>
                            <MenuItem value={Locale.FRENCH}>French</MenuItem>
                            <MenuItem value={Locale.DEUTSCH}>Deutsch</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={isDefaultContact}
                                    checked={contactCreateShuttle.defaultField || false}
                                    onChange={(e) => {
                                        const newContactCreateShuttle = Object.assign({}, contactCreateShuttle);
                                        newContactCreateShuttle.defaultField = e.target.checked;
                                        setContactCreateShuttle(newContactCreateShuttle);
                                        console.log(newContactCreateShuttle);
                                    }}
                                />
                            }
                            label="Default"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={8}>
                        <Box>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <div>
                                    <HtmlTooltip
                                        arrow
                                        placement="top-end"
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={openTooltip}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={
                                            <React.Fragment>
                                                <Box sx={{
                                                    textAlign: "center"
                                                }}>
                                                    <Paper elevation={24}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>

                                                                <Typography variant="h6">
                                                                    Delete contact
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body1">
                                                                    Are you sure ?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button onClick={handleTooltipClose} autoFocus>No</Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button onClick={deleteContact}>Yes</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Box>
                                            </React.Fragment>
                                        }
                                    >
                                        <DeleteOutlineIcon style={{ color: '#a1a1a1' }} onClick={(e) => {
                                            e.stopPropagation();
                                            handleTooltipOpen();
                                        }} />
                                    </HtmlTooltip>

                                </div>
                            </ClickAwayListener>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={handleSave}>Save</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog >
    </>);
}