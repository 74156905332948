/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import useIds from '../../Services/idsHook';
import SignIn from './SignIn';

export interface AuthProps { children: React.ReactNode }

export const Auth: React.FC<AuthProps> = (props) => {
    const [getIds, secretKey] = useIds();
    const [Authenticated, setAuthenticated] = useState<boolean>();
    useEffect(() => {
        let ids = getIds();
        if (ids) setAuthenticated(true);
        else setAuthenticated(false);
    }, []);
    return (<>
        {Authenticated === true && <>{props.children}</>}
        {Authenticated === false && <><SignIn /></>}
    </>);
}
