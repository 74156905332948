import { format } from 'date-fns'
import { EntitlementState } from './soc-sentinel-web-api';

export default function useEntitlementTools() {

    function estNombre(valeur: any): boolean {
        return typeof valeur === 'number';
    }

    function getState(entitlement: any) {
        let ret = ""
        let aTester = entitlement;
        if (!estNombre(entitlement)) {
            aTester = entitlement.entState;
        }
        switch (aTester) {
            case EntitlementState.Draft: ret = "Draft"; break;
            case EntitlementState.Queued: ret = "Queued"; break;
            case EntitlementState.ProductKeysGenerated: ret = "Product Keys Generated"; break;
            case EntitlementState.Produced: ret = "Produced"; break;
            case EntitlementState.Completed: ret = "Completed"; break;
            case EntitlementState.Acknowledged: ret = "Acknowledged"; break;
            default: ret = entitlement.entState; break;
        }
        return ret;
    }

    function getLineItemType(entitlement: any) {
        let ret = ""
        switch (entitlement.entitlementItem[0].lineItemType) {
            case 0: ret = "Hardware Key"; break;
            case 1: ret = "Product Key"; break;
            case 2: ret = "Key Update"; break;
            case 3: ret = "Cloud Named"; break;
            case 4: ret = "Cloud UnNamed"; break;
        }
        return ret;
    }

    function getAttributeValue(entitlement: any, attributeName: string): any {
        if (entitlement) {
            var COUNT_CRITERIA = entitlement?.entitlementItem[0]?.itemProduct[0]?.product?.productFeatureRef[0]?.productFeatureLicenseModel?.licenseModel?.lmAttribute?.find((a: any) => a.attribute.attributeName === attributeName);
            return COUNT_CRITERIA;
        }
        return undefined
    }

    function getEXPIRATION_DATE(entitlement: any) {
        let attributeValue = getAttributeValue(entitlement, "EXPIRATION_DATE")?.attributeValue;
        if (attributeValue) {
            return format(new Date(attributeValue), 'dd/MM/yyyy');
        }
        return "";
    }

    function getCONCURRENT_INSTANCES(entitlement: any) {
        let attributeValue = getAttributeValue(entitlement, "CONCURRENT_INSTANCES")?.attributeValue;
        if (attributeValue === "10000000") {
            return "No Limit";
        }
        return attributeValue;
    }

    function getLICENSE_TYPE(entitlement: any) {
        let attributeValue = getAttributeValue(entitlement, "LICENSE_TYPE")?.attributeValue;

        let ret = "";
        switch (attributeValue) {
            case "0": ret = "Perpetual"; break;
            case "1": ret = "Execution Count"; break;
            case "2": ret = "Expiration Date"; break;
            default: ret = attributeValue; break;
        }

        return ret;
    }

    function getNETWORK_ENABLED(entitlement: any) {
        let attributeValue = getAttributeValue(entitlement, "NETWORK_ENABLED")?.attributeValue;
        return stringToBoolean(attributeValue);
    }

    function stringToBoolean(str: string): boolean {
        return str.toLowerCase() === "true";
    }

    return [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE, getLineItemType, getNETWORK_ENABLED] as const;
}