import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useIds from "../../Services/idsHook";
import { IEntitlementCreateShuttle, SocSentinelWebApi } from "../../Services/soc-sentinel-web-api";
import React from "react";
import { MessageBox } from "../Tools/MessageBox";
import { EntitlementDetails, EntitlementDetailsRef } from "./EntitlementDetails";

export interface IEntitlementDialogProps {
    title?: string,
    customerId?: number,
    open: boolean,
    onClose?: (entitlementDialog: IEntitlementDialog) => void;
    vendorId: number,
    isBlank?: boolean,
    entId?: number,
}

export enum Locale {
    ENGLISH = "en",
    FRENCH = "fr",
    DEUTSCH = "de"
}

export interface IEntitlementDialog {
    dataCreatedOrOpdated: boolean
}

export const EntitlementDialog: React.FC<IEntitlementDialogProps> = (props) => {

    const childRef = useRef<EntitlementDetailsRef>(null);

    const [getIds, secretKey] = useIds();
    const [openModal, setOpenModal] = useState(false);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [entitlementCreateShuttle, setEntitlementCreateShuttle] = React.useState<IEntitlementCreateShuttle | undefined>(undefined);

    useEffect(() => {
        setOpenModal(props.open);
    }, [props]);

    const handleAlerteClose = () => { setAlertOpen(false); };
    const close = (dataCreatedOrUpdated: boolean) => {
        setOpenModal(false);
        if (props.onClose) {
            props.onClose({ dataCreatedOrOpdated: dataCreatedOrUpdated });
        }
    };

    return (
        <>
            <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
            <Dialog
                maxWidth='lg'
                fullWidth={true}
                open={openModal}
                onClose={(e: any) => {
                    close(false);
                }}>
                <DialogTitle>{props.title ? props.title : "Entitlement"}</DialogTitle>
                <DialogContent style={{ paddingTop: '5px' }}>
                    <EntitlementDetails
                        ref={childRef}
                        vendorId={props.vendorId}
                        isInModal={true}
                        isBlank={props.isBlank}
                        entId={props.entId}
                        onShuttleChange={(entitlementCreateShuttle: IEntitlementCreateShuttle) => {
                            setEntitlementCreateShuttle(entitlementCreateShuttle);
                        }}
                        onEntitlementCreated={(r) => { close(true); }} />
                </DialogContent>
                <DialogActions>
                    <Grid alignItems="center" container spacing={3}>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={1}>
                            <Button onClick={(e: any) => { close(false); }}>Cancel</Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button onClick={() => { childRef?.current?.triggerAction(); }}>Save</Button>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={openBackDrop}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog >
        </>);
}