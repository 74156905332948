import * as React from 'react';
import { useEffect, useImperativeHandle, useMemo, useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EntitlementAction, EntitlementState, IAuthShuttle, ICustomerContactsShuttle, ICustomerDetailsShuttle, IEntitlementCreateShuttle, IEntitlementsShuttle, IProductSearchShuttle, LicenseModelType, SocSentinelWebApi } from '../../Services/soc-sentinel-web-api';
import { useLoaderData, useNavigate } from "react-router-dom";
import { Autocomplete, Backdrop, CircularProgress, ClickAwayListener, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Switch, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import useIds from '../../Services/idsHook';
import useEntitlementTools from '../../Services/tools';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import KeyIcon from '@mui/icons-material/Key';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
//import { v4 as uuidv4 } from 'uuid';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import dayjs, { Dayjs } from 'dayjs';
import { MessageBox } from '../Tools/MessageBox';
import { format } from 'date-fns';

export async function loader(params: any) {
    return {
        entitlementId: params['params'].entitlementId as number,
        crmId: params['params'].crmId as string,
        customerId: params['params'].customerId as number,
        keyId: params['params'].keyId as string,
    };
}

export interface EntitlementDetailsProps {
    vendorId: number,
    entitlementId?: number,
    crmId?: string,
    customerId?: number,
    isInModal?: boolean,
    isBlank?: boolean,
    entId?: number,
    onShuttleChange?: (shuttle: IEntitlementCreateShuttle) => void;
    onEntitlementCreated?: (entitlementDetails: any) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface EntitlementDetailsRef {
    triggerAction: () => void;
}

export const EntitlementDetails = React.forwardRef<EntitlementDetailsRef, EntitlementDetailsProps>((props, ref) => {
    const [getIds, secretKey] = useIds();
    const [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE, getLineItemType, getNETWORK_ENABLED] = useEntitlementTools();
    const routeData = useLoaderData() as any;
    const [isInModal, setIsInModal] = useState<boolean>(false);
    const [products, setProducts] = useState<any[] | undefined>(undefined);
    const [contacts, setContacts] = useState<any[] | undefined>(undefined);
    const [contactValue, setContactValue] = React.useState<any | undefined>(undefined);
    const [produtsValue, setProductsValue] = React.useState<any[] | undefined>([]);
    const [refIDs1, setRefIDs1] = useState<any[] | undefined>(undefined);
    const [refID1Value, setRefID1Value] = React.useState<any | undefined>(null);
    const [licenceType, setLicenceType] = React.useState(LicenseModelType.ExpirationDate.toString());
    const [description, setDescription] = React.useState<string>("");
    const [entitlementAction, setEntitlementAction] = React.useState(EntitlementAction.SAVE.toString());
    const [entitlementState, setEntitlementState] = React.useState<EntitlementState>(EntitlementState.Unknown);
    const [createdBy, setCreatedBy] = React.useState<string>("");
    const [EntitlmentOrigine, setEntitlmentOrigine] = React.useState<any[] | undefined>(undefined);
    const [customerName, setCustomerName] = React.useState<string>("");
    const [entitlementDisable, setEntitlementDisable] = React.useState<boolean>(false);
    const [entitlementCreateShuttle, setEntitlementCreateShuttle] = React.useState<IEntitlementCreateShuttle | undefined>(undefined);
    const [concurrentInstances, setConcurrentInstances] = React.useState(1);
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs());
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs().add(6, 'week'));
    const [noMaj, setNoMaj] = useState<boolean>(false);
    const [eid, setEid] = useState<string | undefined>(undefined);
    const [productKeyId, setProductKeyId] = useState<string | undefined>(undefined);
    const [keyID, setKeyID] = useState<string | undefined>(undefined);
    const [lineItemType, setLineItemType] = useState<string | undefined>(undefined);
    const [customerId, setCustomerId] = useState<string | undefined>(undefined);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [productsError, setProductsError] = useState(false);
    const [contactsError, setContactsError] = useState(false);
    const [nbInstancesError, setNbInstancesError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [endDateErrorMessage, setEndDateErrorMessage] = useState("");
    const [sendMail, setSendMail] = useState<boolean>(false);
    const [network, setNetwork] = useState<boolean>(true);
    const [sendEmailVisible, setSendEmailVisible] = useState<boolean>(false);

    const RefIDS = [
        { title: "Suites Education - Etudiants + enseignants", code: "EDUC" },
        { title: "Suites Education - Organismes de formation", code: "OF" },
        { title: "DeviSOC ESTIMATION", code: "DVSEST" },
        { title: "DeviSOC ECONOMIE DU PROJET", code: "DVSEDP" },
        { title: "DeviSOC MAITRISE D'OEUVRE", code: "DVSMOE" },
        { title: "DeviSOC CONTRACTANT", code: "DVSCG" },
        { title: "DeviSOC SUIVI EXE", code: "DVSSE" },
        { title: "BIM C", code: "BIMC" },
        { title: "JUSTBIM", code: "JB" },
        { title: "JUSTBIM 5D", code: "JB5D" },
        { title: "JUSTBIM PLAN", code: "JBPLAN" },
        { title: "BATI CCTP", code: "BATICCTP" },
        { title: "CLAUSES CONSTRUCTIONS", code: "CC" },
        { title: "DeviSOC TEST", code: "DVSTEST" },
        { title: "JUSTBIM TEST", code: "JBTEST" },
        { title: "DeviSOC PRET", code: "DVSPRET" },
        { title: "JUSTBIM PRÊT", code: "JBPRET" },
        { title: "DeviSOC LOCATION LICENCE", code: "DVSLOC" },
        { title: "JUSTBIM LOCATION LICENCE", code: "JBLOC" },
        { title: "Serveur de projets", code: "SP" },
        { title: "JUSTBIM PRO", code: "JBPRO" },
        { title: "JUSTBIM MAX", code: "JBMAX" },
        { title: "JUSTBIM STARTER", code: "JBSTARTER" },
        { title: "DeviSOC CCTP", code: "DVSCCTP" }
    ];

    useEffect(() => {

        if (props.isInModal) {
            setIsInModal(true);
            setSendMail(false);
        }
        else { setSendMail(true); }

        var RefIDSArray = RefIDS.sort((n1, n2) => {
            if (n1.code > n2.code) { return 1; }
            if (n1.code < n2.code) { return -1; }
            return 0;
        });
        setRefIDs1(RefIDSArray);

        let ids = getIds();
        let restAPI = new SocSentinelWebApi();

        let tempAuthShuttle: IAuthShuttle = { cryptedIds: ids.cryptedIds }

        let customerId: string | undefined = undefined;
        let crmId: string | undefined = undefined;
        if (props.isBlank) {
            let shuttleEntitlementZOB: IEntitlementsShuttle = {
                authShuttle: tempAuthShuttle,
                vendorId: props.vendorId,
                entId: props.entId
            }
            restAPI.GetEntitlementCreateShuttle(shuttleEntitlementZOB).then((r) => {
                if (!customerId && r) customerId = r.customerId?.toString();
                setCustomerId(customerId);
                load(undefined, customerId, undefined, r, tempAuthShuttle);
            })
        }
        else if (routeData.customerId || routeData.crmId) {
            customerId = routeData.customerId;
            setCustomerId(customerId);
            crmId = routeData.crmId;
            if (crmId && !customerId) {
                let shuttleDetails: ICustomerDetailsShuttle = {
                    authShuttle: tempAuthShuttle,
                    customerId: customerId,
                    crmId: crmId
                }

                restAPI.CustomerGetID(shuttleDetails)
                    .then((u) => {
                        customerId = u.toString();
                        setCustomerId(customerId);
                        load(undefined, customerId, crmId, undefined, tempAuthShuttle);
                    })
                    .catch((a) => {
                        //debugger
                        setAlertMessage(a);
                        setAlertOpen(true);
                        setOpenBackDrop(false);
                    });
            }
            else load(undefined, customerId, crmId, undefined, tempAuthShuttle);
        }
        else if (routeData.entitlementId) {
            let shuttleEntitlement: IEntitlementsShuttle = {
                authShuttle: tempAuthShuttle,
                entId: routeData.entitlementId
            }
            restAPI.EntitlementsByID(shuttleEntitlement)
                .then((u) => {
                    customerId = u[0].customerId;
                    setCustomerId(customerId);
                    load(u, customerId, crmId, undefined, tempAuthShuttle);
                })
                .catch((a) => {
                    //debugger
                    setAlertMessage(a);
                    setAlertOpen(true);
                    setOpenBackDrop(false);
                });
        }
        else if (routeData.keyId) {
            let shuttleEntitlement: IEntitlementsShuttle = {
                authShuttle: tempAuthShuttle,
                keyID: routeData.keyId
            }
            restAPI.EntitlementsByKeyID(shuttleEntitlement)
                .then((u) => {
                    //debugger
                    customerId = u[0].customerId;
                    setCustomerId(customerId);
                    load(u, customerId, crmId, undefined, tempAuthShuttle);
                })
                .catch((a) => {
                    //debugger
                    setAlertMessage(a);
                    setAlertOpen(true);
                    setOpenBackDrop(false);
                });
        }

    }, [routeData]);

    const setShuttle = (shuttle: IEntitlementCreateShuttle) => {
        setEntitlementCreateShuttle(shuttle);
        if (props.onShuttleChange) props.onShuttleChange(shuttle);
    }

    const load = (entitlement: any, customerId: string | undefined, crmId: string | undefined, entitlementCreateShuttle: IEntitlementCreateShuttle | undefined, authShuttle: IAuthShuttle) => {

        //debugger
        // if (!customerId && entitlementCreateShuttle) customerId = entitlementCreateShuttle.customerId?.toString();

        if (customerId && authShuttle) {
            let tempLicenceType = licenceType;
            let tempStartDate = startDate?.toDate();
            let tempEndDate = endDate?.toDate();
            let tempRefID1 = refID1Value;
            let tempNoMaj = noMaj;
            let tempConcurrentInstances = concurrentInstances;
            let tempKeyID = null;
            let tempEntitlementAction = EntitlementAction.SAVE;
            let tempNetwork = network;

            let SendEmailVisible = false;
            if (entitlement) {

                setEntitlmentOrigine(entitlement);
                setCreatedBy(entitlement[0].createdBy);
                setEntitlementDisable(!entitlement[0].enabled);
                setEntitlementState(entitlement[0].entState);
                setDescription(entitlement[0].description);

                SendEmailVisible = !isInModal
                    && entitlement[0].entState !== EntitlementState.Draft
                    && entitlement[0].entState !== EntitlementState.Queued
                    && entitlement[0].entState !== EntitlementState.ProductKeysGenerated;
                setSendEmailVisible(SendEmailVisible);
                setSendMail(SendEmailVisible);

                switch (getState(entitlement[0])) {
                    case "Draft": tempEntitlementAction = EntitlementAction.SAVE; break;
                    case "Queued": tempEntitlementAction = EntitlementAction.QUEUE; break;
                    case "Product Keys Generated": tempEntitlementAction = EntitlementAction.PRODUCE; break;
                    case "Produced": tempEntitlementAction = EntitlementAction.PRODUCE; break;
                    case "Completed": tempEntitlementAction = EntitlementAction.PRODUCE; break;
                    case "Acknowledge":
                    default: tempEntitlementAction = EntitlementAction.NONE; break;
                }
                setEntitlementAction(tempEntitlementAction.toString());
                //debugger
                setLineItemType(getLineItemType(entitlement[0]))
                setEid(entitlement[0].eid);
                setProductKeyId((entitlement[0].entitlementItem[0]?.productKeyId) ? entitlement[0].entitlementItem[0]?.productKeyId[0] : null);
                tempKeyID = (entitlement[0].entitlementItem[0]?.keyId) ? entitlement[0].entitlementItem[0]?.keyId[0] : null;
                setKeyID(tempKeyID);

                //debugger

                let txtStartDate = entitlement[0].startDate.replace("T00:00:00", "");
                let txtEndDate = getEXPIRATION_DATE(entitlement[0]);
                tempStartDate = new Date(txtStartDate);
                const dateComponents: string[] = txtEndDate.split('/');
                let txtEndDateTemp = dateComponents[2] + "-" + dateComponents[1] + "-" + dateComponents[0];
                tempEndDate = new Date(txtEndDateTemp);
                //tempEndDate = new Date(parseInt(dateComponents[2]), parseInt(dateComponents[1]) - 1, parseInt(dateComponents[0]));
                tempStartDate.setUTCHours(8, 0, 0, 0);
                tempEndDate.setUTCHours(8, 0, 0, 0);

                if (entitlement[0].refId1) {
                    tempRefID1 = RefIDS.find(e => e.code === entitlement[0].refId1.replace("-NOMAJ", ''))
                    tempNoMaj = entitlement[0].refId1.includes("NOMAJ");
                }

                tempConcurrentInstances = +getCONCURRENT_INSTANCES(entitlement[0]);

                let licenseModelName = entitlement[0].entitlementItem[0].itemProduct[0].product.productFeatureRef[0].productFeatureLicenseModel.licenseModel?.licenseModelName;
                switch (licenseModelName) {
                    case "Perpetual": tempLicenceType = LicenseModelType.Perpetual.toString(); break;
                    case "Expiration Date": tempLicenceType = LicenseModelType.ExpirationDate.toString(); break;
                }

                tempNetwork = getNETWORK_ENABLED(entitlement[0]);
            }

            // setStartDate(dayjs(tempStartDate));
            // setEndDate(dayjs(tempEndDate));
            // setRefID1Value(tempRefID1);
            // setNoMaj(tempNoMaj);
            // setLicenceType(tempLicenceType);
            // setConcurrentInstances(tempConcurrentInstances);

            let strRefID1 = tempRefID1?.code;
            if (!strRefID1 || strRefID1 === "") strRefID1 = noMaj ? "NOMAJ" : "";
            else strRefID1 += noMaj ? "-NOMAJ" : "";

            let restAPI = new SocSentinelWebApi();
            let tempEntitlementCreateShuttle: IEntitlementCreateShuttle = {
                entId: (entitlement) ? entitlement[0].entId : undefined,
                authShuttle: authShuttle,
                customerId: +customerId,
                vendorId: props.vendorId,
                startDate: tempStartDate,
                endDate: tempEndDate,
                concurrentInstances: tempConcurrentInstances,
                countCriteria: "2",
                licenseModelType: +tempLicenceType,
                entitlementAction: tempEntitlementAction,
                refID1: strRefID1,
                keyID: tempKeyID,
                sendMail: SendEmailVisible,
                description: (entitlement) ? entitlement[0].description : "",
                network: tempNetwork
            }


            if (entitlementCreateShuttle) {
                //debugger
                tempEntitlementCreateShuttle.entId = undefined;
                tempEntitlementCreateShuttle.customerId = entitlementCreateShuttle.customerId;
                tempEntitlementCreateShuttle.vendorId = entitlementCreateShuttle.vendorId;
                tempEntitlementCreateShuttle.licenseModelType = entitlementCreateShuttle.licenseModelType;
                tempEntitlementCreateShuttle.startDate = entitlementCreateShuttle.startDate;
                tempEntitlementCreateShuttle.endDate = entitlementCreateShuttle.endDate;
                if (entitlementCreateShuttle.licenseModelType === LicenseModelType.Perpetual) {
                    tempEntitlementCreateShuttle.endDate = new Date('2500-12-31');
                }
                tempEntitlementCreateShuttle.concurrentInstances = entitlementCreateShuttle.concurrentInstances;
                tempEntitlementCreateShuttle.countCriteria = entitlementCreateShuttle.countCriteria;
                tempEntitlementCreateShuttle.entitlementAction = undefined;
                tempEntitlementCreateShuttle.refID1 = entitlementCreateShuttle.refID1;
                tempEntitlementCreateShuttle.keyID = undefined;
                tempEntitlementCreateShuttle.sendMail = entitlementCreateShuttle.sendMail;
                tempEntitlementCreateShuttle.description = entitlementCreateShuttle.description;
                tempEntitlementCreateShuttle.network = entitlementCreateShuttle.network;

                tempRefID1 = RefIDS.find(e => e.code === entitlementCreateShuttle.refID1?.replace("-NOMAJ", ''))
                tempNoMaj = entitlementCreateShuttle.refID1 ? entitlementCreateShuttle.refID1.includes("NOMAJ") : false;
                setDescription(tempEntitlementCreateShuttle.description ? tempEntitlementCreateShuttle.description : "");
            }

            //debugger
            setNetwork(tempEntitlementCreateShuttle.network ? tempEntitlementCreateShuttle.network : tempNetwork);

            setStartDate(dayjs(tempEntitlementCreateShuttle.startDate));
            setEndDate(dayjs(tempEntitlementCreateShuttle.endDate));
            setRefID1Value(tempRefID1);
            setNoMaj(tempNoMaj);
            setLicenceType(tempEntitlementCreateShuttle.licenseModelType ? tempEntitlementCreateShuttle.licenseModelType.toString() : tempLicenceType);
            setConcurrentInstances(tempEntitlementCreateShuttle.concurrentInstances ? tempEntitlementCreateShuttle.concurrentInstances : tempConcurrentInstances);

            let shuttleContacts: ICustomerContactsShuttle = {
                authShuttle: authShuttle,
                customerId: customerId,
                crmId: crmId
            }

            restAPI.CustomerContacts(shuttleContacts)
                .then((u) => {
                    setContacts(u);
                    let defaultContact = u.find(e => e.default);
                    if (!defaultContact) {
                        if (u.length === 1) defaultContact = u[0];
                    }
                    let selectedContact = defaultContact;
                    //debugger
                    if (entitlement) {
                        selectedContact = u.find(e => e.emailId === entitlement[0].customerEmail);
                        tempEntitlementCreateShuttle.customerEmail = selectedContact?.emailId;
                    }
                    else if (entitlementCreateShuttle) {
                        //debugger
                        selectedContact = u.find(e => e.emailId === entitlementCreateShuttle.customerEmail);
                        tempEntitlementCreateShuttle.customerEmail = entitlementCreateShuttle.customerEmail;
                    }
                    else {
                        tempEntitlementCreateShuttle.customerEmail = selectedContact?.emailId;
                    }

                    setContactValue(selectedContact);
                    setShuttle(tempEntitlementCreateShuttle);
                })
                .catch((a) => {
                    //debugger;
                    setAlertMessage(a);
                    setAlertOpen(true);
                    setOpenBackDrop(false);
                });

            let shuttleProductSearchShuttle: IProductSearchShuttle = {
                authShuttle: authShuttle,
                vendorId: props.vendorId,
                productName: null,
                lifeCycleStage: null,
                refId1: null,
                refId2: null,
                pageIndex: null,
                pageSize: 1000,
                sortCol: null,
                sortOrder: null,
            }
            restAPI.ProductSearch(shuttleProductSearchShuttle)
                .then((u) => {
                    let products: any[] = [];
                    //debugger
                    u.instances?.forEach(product => {
                        //if (product.deployed === true && product.lifeCycleStage !== "EOL") products.push({ name: product.name, id: product.id });
                        if (product.deployed === true/* && product.lifeCycleStage !== "EOL"*/) products.push({ name: product.name, id: product.id, eol: product.lifeCycleStage === "EOL" });
                    });
                    //debugger
                    setProducts(products);

                    if (entitlement) {
                        let defalutProducts: any[] = [];
                        tempEntitlementCreateShuttle.productIds = [];
                        entitlement[0].entitlementItem[0].itemProduct.forEach((prod: any) => {
                            tempEntitlementCreateShuttle.productIds?.push(+prod.productId);
                            defalutProducts.push(products.find(e => e.id === +prod.productId));
                        });
                        //debugger
                        setProductsValue(defalutProducts);
                        setShuttle(tempEntitlementCreateShuttle);
                    }
                    else if (entitlementCreateShuttle) {
                        //debugger
                        let defalutProducts: any[] = [];
                        tempEntitlementCreateShuttle.productIds = [];
                        entitlementCreateShuttle?.productIds?.forEach((prod: any) => {
                            tempEntitlementCreateShuttle.productIds?.push(prod);
                            defalutProducts.push(products.find(e => e.id === prod));
                        });
                        setProductsValue(defalutProducts);
                        setShuttle(tempEntitlementCreateShuttle);
                    }

                })
                .catch((a) => {
                    //debugger;
                    if (entitlement) {
                        let Products: any[] = [];
                        tempEntitlementCreateShuttle.productIds = [];
                        entitlement[0].entitlementItem[0].itemProduct.forEach((prod: any) => {
                            tempEntitlementCreateShuttle.productIds?.push(+prod.productId);
                            Products.push({ name: prod.product.productName, id: prod.productId });
                        });
                        setProducts(Products);
                        setProductsValue(Products);
                        setShuttle(tempEntitlementCreateShuttle);
                    }
                    else {
                        setAlertMessage(a);
                        setAlertOpen(true);
                        setOpenBackDrop(false);
                    }
                });

            let shuttleDetails: ICustomerDetailsShuttle = {
                authShuttle: authShuttle,
                customerId: customerId,
                crmId: crmId
            }

            if (!isInModal) {
                restAPI.CustomerDetails(shuttleDetails)
                    .then((u) => {
                        let name = (u.type === 0 ? u.name : u.defaultContact.lastName + " " + u.defaultContact.firstName);
                        document.title = "Add/Update Entitlement - " + name;
                        setCustomerName(name);
                    })
                    .catch((ex: any) => {
                        //debugger
                        setAlertMessage(ex);
                        setAlertOpen(true);
                        setOpenBackDrop(false);
                    });
            }


        } else if (authShuttle) { }
    }

    const navigate = useNavigate();

    const handleAlerteClose = () => { setAlertOpen(false); };
    const triggerAction = () => { Save(); };
    useImperativeHandle(ref, () => ({ triggerAction }));

    const Save = () => {
        //debugger
        let restAPI = new SocSentinelWebApi();
        //debugger
        if (entitlementCreateShuttle) {
            let error = false;

            if (!entitlementCreateShuttle.productIds || entitlementCreateShuttle.productIds.length === 0) {
                error = true;
                setProductsError(true);
            }
            else setProductsError(false);

            if (!entitlementCreateShuttle.customerEmail || entitlementCreateShuttle.customerEmail === "") {
                error = true;
                setContactsError(true);
            }
            else setContactsError(false);

            if (!entitlementCreateShuttle.concurrentInstances || entitlementCreateShuttle.concurrentInstances === 0) {
                error = true;
                setNbInstancesError(true);
            }
            else setNbInstancesError(false);

            if (!entitlementCreateShuttle.startDate) {
                error = true;
                setStartDateError(true);
            }
            else setStartDateError(false);

            if (!entitlementCreateShuttle.endDate) {
                error = true;
                setEndDateError(true);
                setEndDateErrorMessage("Missing");
            }
            else {
                if (entitlementCreateShuttle.startDate) {
                    if (entitlementCreateShuttle.endDate < entitlementCreateShuttle.startDate) {
                        error = true;
                        setEndDateError(true);
                        setEndDateErrorMessage("The end date must be after the start date");
                    }
                }
                else setEndDateError(false);
            }

            if (!error) {
                setOpenBackDrop(true);
                restAPI.EntitlementCreateOrUpdate(entitlementCreateShuttle).then((r) => {
                    navigate("/sentinel/customer/" + customerId);
                    setOpenBackDrop(false);
                    if (props.onEntitlementCreated) {
                        props.onEntitlementCreated(r);
                    }
                }).catch((a) => {
                    //debugger;
                    setAlertMessage(a);
                    setAlertOpen(true);
                    setOpenBackDrop(false);
                });
            }

        }

    };

    const errorStartDateMessage = useMemo(() => {
        if (startDateError) return "Missing"
        else return '';
    }, [startDateError]);

    const errorEndDateMessage = useMemo(() => {
        if (endDateError) return endDateErrorMessage
        else return '';
    }, [endDateError, endDateErrorMessage]);

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgb(255 255 255 / 0%)',
            width: 1000,
        },
    }));

    const [openTooltip, setOpenTooltip] = React.useState(false);
    const handleTooltipClose = () => { setOpenTooltip(false); };
    const handleTooltipOpen = () => { setOpenTooltip(true); };

    const deleteContact = (e: any) => {
        let restAPI = new SocSentinelWebApi();
        if (entitlementCreateShuttle) {
            setOpenBackDrop(true);
            setOpenTooltip(false);
            restAPI.EntitlementDelete(entitlementCreateShuttle).then((r) => {
                navigate("/sentinel/customer/" + customerId);
                setOpenBackDrop(false);
            }).catch((a) => {
                //debugger;
                setAlertMessage(a);
                setAlertOpen(true);
                setOpenBackDrop(false);
            });
        }
    }

    function State(props: any) {
        useEffect(() => {

            // debugger
            // console.log(props);

        }, []);
        const [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE, getLineItemType] = useEntitlementTools();
        return (
            <Grid
                container
                spacing={1}
                direction='row'
                alignItems="center"
            >
                <Grid item>
                    <Box>
                        {
                            props?.entState === EntitlementState.Draft && <DriveFileRenameOutlineOutlinedIcon style={{ color: '#0082B4', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entState === EntitlementState.Queued && <AccessTimeIcon style={{ color: '#20C997', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entState === EntitlementState.ProductKeysGenerated && <KeyIcon style={{ color: '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entState === EntitlementState.Produced && <DoneAllIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entState === EntitlementState.Completed && <DoneIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entState === EntitlementState.Acknowledged && <DoneAllIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entState !== 5 &&
                            props?.entState !== 4 &&
                            props?.entState !== 3 &&
                            props?.entState !== 2 &&
                            props?.entState !== 1 &&
                            props?.entState !== 0 &&
                            <span>{props?.entState}</span>
                        }
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Typography variant="h5"
                            style={{ color: !props.disabled ? '' : '#a1a1a1' }}>
                            {getState(props?.entState)}
                        </Typography>
                        <Typography variant="subtitle1"
                            style={{ color: !props.disabled ? '' : '#a1a1a1' }}>
                            {props?.customerName}
                        </Typography>
                        <Typography variant="caption"
                            style={{ color: !props.disabled ? '' : '#a1a1a1' }}>
                            {"Created by : " + props.createdBy}
                        </Typography>
                        {
                            props.disabled &&
                            <Typography variant="overline"
                                style={{ color: '#a1a1a1' }}>
                                {' - Disabled'}
                            </Typography>
                        }
                    </Box>
                </Grid>
            </Grid >
        );
    }

    return (
        <>
            <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
            <Grid container spacing={2}>
                {
                    (!entitlementCreateShuttle) &&
                    <Grid item xs={12}>
                        <React.Fragment>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </React.Fragment>
                    </Grid>
                }
                {
                    (entitlementCreateShuttle) &&
                    <>
                        {
                            !isInModal &&
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    direction='row'
                                    alignItems="center"
                                >
                                    <Grid item xs={4}>
                                        <State
                                            fontSize={30}
                                            disabled={entitlementDisable}
                                            entState={entitlementState}
                                            createdBy={createdBy}
                                            customerName={customerName}
                                        />
                                    </Grid>

                                    {/* lineItemType */}
                                    <Grid item xs={1}>
                                        {
                                            lineItemType &&
                                            <TextField
                                                margin="dense"
                                                id="lineItemType"
                                                label="Item Type"
                                                type="text"
                                                fullWidth
                                                defaultValue={lineItemType}
                                                variant="standard"
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        }
                                    </Grid>

                                    {/* eid */}
                                    <Grid item xs={3}>
                                        {
                                            eid &&
                                            <TextField
                                                margin="dense"
                                                id="eid"
                                                label="EID"
                                                type="text"
                                                fullWidth
                                                defaultValue={eid}
                                                variant="standard"
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        }
                                    </Grid>

                                    {/* keyID */}
                                    {
                                        keyID && productKeyId &&
                                        <Grid item xs={4}>
                                            <TextField
                                                margin="dense"
                                                id="keyid"
                                                label="Key ID"
                                                type="text"
                                                fullWidth
                                                defaultValue={keyID}
                                                variant="standard"
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            />
                                        </Grid>
                                    }

                                    {/* productKeyId */}
                                    {
                                        !keyID && productKeyId &&
                                        <>
                                            <Grid item xs={4}>
                                                <TextField
                                                    margin="dense"
                                                    id="productKeyId"
                                                    label="Product Key ID"
                                                    type="text"
                                                    fullWidth
                                                    defaultValue={productKeyId}
                                                    variant="standard"
                                                    InputProps={
                                                        {
                                                            readOnly: true,
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        title="Create a simplified email"
                                                                        size='small'
                                                                        //style={{ cursor: 'default' }}
                                                                        onClick={() => {
                                                                            const subject = "SOC Informatique - Activation de licence";
                                                                            const productKey = productKeyId;
                                                                            let startDate = "";
                                                                            if (entitlementCreateShuttle.startDate) {
                                                                                const startDateTemp: Date = entitlementCreateShuttle.startDate;
                                                                                startDate = format(startDateTemp, 'dd/MM/yyyy');
                                                                            }
                                                                            const contactName = contactValue.name;
                                                                            const customerEmail = entitlementCreateShuttle.customerEmail;
                                                                            const concurrentInstances = entitlementCreateShuttle.concurrentInstances;
                                                                            let produits = "";
                                                                            if (produtsValue) {
                                                                                produtsValue.forEach(element => {
                                                                                    produits += "| --- " + element.name + "\n";
                                                                                });
                                                                            }

                                                                            const emailBody =
                                                                                `Bonjour ${contactName},

Une licence a été créée :

| - Date de début : ${startDate}
| - Client : ${customerName}
| - E-mail : ${customerEmail}
| - Clé de produit : ${productKey}
| - Quantité (par clé de produit) : ${concurrentInstances}
| - Produits :
${produits}
`;

                                                                            const complement = `----------

1 - Télécharger et installer le produit :
Vous trouverez sur notre Aide en ligne les liens de téléchargement et l'aide à l'installation de tous nos produits.

2 - Activer la licence :
|  Cas n°1 : Activer la licence sur votre ordinateur
| - Démarrez le logiciel
| - Cliquez sur "Activer une licence" dans la fenêtre de sélection des licences (bouton en haut à droite)
| - Renseignez la clé de produit
| - Cliquez sur "Activer"

|  Cas n°2 : Activer la licence sur votre serveur (Workgroup, TSE)
| - Connectez-vous sur votre serveur
| - Téléchargez et installez le serveur de licences
| - Démarrez le serveur de licences
| - Cliquez sur "Activer une licence" dans la fenêtre de sélection des licences (bouton en haut à droite)
| - Renseignez la clé de produit
| - Cliquez sur "Activer"

`;

                                                                            const fin = `Si vous rencontrez des difficultés, n'hésitez pas à nous contacter.`;
                                                                            //debugger
                                                                            let body = (emailBody.length + complement.length + fin.length < 1200) ? emailBody + complement + fin : emailBody + fin;

                                                                            window.location.href = `mailto:${customerEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

                                                                        }}>
                                                                        <ForwardToInboxIcon style={{ color: '#a1a1a1', fontSize: 20 }} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </Grid>
                        }

                        {/* products */}
                        <Grid item xs={!sendEmailVisible ? 6 : 5}>
                            {
                                products &&
                                <>
                                    <Autocomplete
                                        value={produtsValue}
                                        multiple
                                        id="checkboxes-tags-demo"
                                        //options={products}
                                        options={products.filter(p => p.eol === false)}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => {
                                            console.log(option);
                                            if (option) return option.id + " - " + option.name + ((option.eol === true) ? " (End Of Life)" : "")
                                            else return "/!\\ -- Unknown product -- /!\\";
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li key={option.id} {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                error={productsError}
                                                helperText={productsError ? "Missing" : ""}
                                                label="Products *"
                                                placeholder=""
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            setProductsValue(value);
                                            const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                            newEntitlementCreateShuttle.productIds = [];
                                            value.forEach(prod => { newEntitlementCreateShuttle.productIds?.push(prod.id); });
                                            setShuttle(newEntitlementCreateShuttle);
                                        }}
                                    />
                                </>
                            }
                        </Grid>

                        {/* Network */}
                        <Grid item xs={1}>
                            <FormControlLabel
                                control={<Switch name="nework" size="small" />}
                                label="Network"
                                labelPlacement="top"
                                checked={network}
                                onChange={(event: React.SyntheticEvent, checked: boolean) => {
                                    setNetwork(checked);
                                    const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                    newEntitlementCreateShuttle.network = checked;
                                    setShuttle(newEntitlementCreateShuttle);
                                }}
                            />
                        </Grid>

                        {/* Contacts */}
                        <Grid item xs={!sendEmailVisible ? 5 : 4}>
                            {
                                contacts &&
                                <>
                                    <Autocomplete
                                        value={contactValue}
                                        id="checkboxes-contacts"
                                        options={contacts}
                                        getOptionLabel={(option) => option.name + " (" + option.emailId + ")"}
                                        renderOption={(props, option, { selected }) => (
                                            <li key={option.id} {...props}>
                                                {option.name + " (" + option.emailId + ")"}
                                                {
                                                    option.default &&
                                                    <CheckIcon />
                                                }
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                error={contactsError}
                                                helperText={contactsError ? "Missing" : ""}
                                                label="Contacts *"
                                                placeholder="" />
                                        )}
                                        onChange={(event, value) => {
                                            setContactValue(value);
                                            const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                            newEntitlementCreateShuttle.customerEmail = value?.emailId;
                                            setShuttle(newEntitlementCreateShuttle);
                                        }}
                                    />
                                </>
                            }
                        </Grid>
                        {
                            sendEmailVisible && //!isInModal && entitlementState !== EntitlementState.Draft && entitlementState !== EntitlementState.Queued &&
                            <>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        //value="top"
                                        control={<Switch color="primary" size="small" />}
                                        label="Send Email"
                                        labelPlacement="top"
                                        checked={sendMail}
                                        onChange={(event: React.SyntheticEvent, checked: boolean) => {
                                            setSendMail(checked);
                                            const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                            newEntitlementCreateShuttle.sendMail = checked;
                                            setShuttle(newEntitlementCreateShuttle);
                                        }}
                                    />
                                </Grid>
                            </>
                        }

                        {/* License Type */}
                        <Grid item xs={2}>
                            <FormControl required>
                                <InputLabel id="demo-simple-select-required-label">License Type</InputLabel>
                                <Select
                                    error={false}
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={licenceType}
                                    label="License Type"
                                    style={{ width: 175 }}
                                    autoWidth
                                    onChange={(event: SelectChangeEvent) => {
                                        const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                        if (event.target.value.toString() === LicenseModelType.ExpirationDate.toString()) {
                                            let tempStartDate = startDate ? startDate : dayjs();
                                            //let tempEndDate = tempStartDate.add(3, 'month');
                                            //debugger
                                            let tempEndDate = tempStartDate.add(6, 'week');
                                            newEntitlementCreateShuttle.endDate = tempEndDate.toDate();
                                            setEndDate(tempEndDate);
                                        }
                                        else if (event.target.value.toString() === LicenseModelType.Perpetual.toString()) {
                                            let tempEndDate = dayjs('2500-12-31');
                                            newEntitlementCreateShuttle.endDate = tempEndDate.toDate();
                                            setEndDate(tempEndDate);
                                        }
                                        setLicenceType(event.target.value.toString());
                                        newEntitlementCreateShuttle.licenseModelType = +event.target.value;
                                        setShuttle(newEntitlementCreateShuttle);
                                    }}
                                >
                                    <MenuItem value={LicenseModelType.ExpirationDate}>ExpirationDate</MenuItem>
                                    <MenuItem value={LicenseModelType.Perpetual}>Perpetual</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Start Date */}
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                <DatePicker
                                    label="Start Date *"
                                    value={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                        newEntitlementCreateShuttle.startDate = date?.toDate();
                                        setShuttle(newEntitlementCreateShuttle);
                                    }}
                                    slotProps={{
                                        textField: {
                                            error: startDateError,
                                            helperText: errorStartDateMessage,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* End Date */}
                        <Grid item xs={2}>
                            {
                                (licenceType === LicenseModelType.ExpirationDate.toString()) &&
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                    <DatePicker
                                        label="End Date *"
                                        value={endDate}
                                        onChange={(date) => {
                                            setEndDate(date);
                                            const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                            newEntitlementCreateShuttle.endDate = date?.toDate();
                                            setShuttle(newEntitlementCreateShuttle);
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: endDateError,
                                                helperText: errorEndDateMessage,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            }
                        </Grid>

                        {/* Concurrent Instances */}
                        <Grid item xs={1}>
                            <Tooltip
                                followCursor
                                placement="right-start"
                                enterDelay={1000}
                                leaveDelay={50}
                                title="Concurrent Instances">
                                <TextField
                                    error={nbInstancesError}
                                    helperText={nbInstancesError ? "Missing" : ""}
                                    id="outlined-error-helper-text"
                                    label="Concurrent Instances *"
                                    type="number"
                                    value={concurrentInstances}
                                    onChange={(event: any) => {
                                        if (event.target.value < 1) event.target.value = 1;
                                        setConcurrentInstances(event.target.value);
                                        const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                        newEntitlementCreateShuttle.concurrentInstances = event.target.value;
                                        setShuttle(newEntitlementCreateShuttle);
                                    }}
                                />
                            </Tooltip>
                        </Grid>

                        {/* refIDs1 */}
                        <Grid item xs={2}>
                            {
                                refIDs1 &&
                                <Autocomplete
                                    value={refID1Value}
                                    id="checkboxes-refid1"
                                    options={refIDs1}
                                    getOptionLabel={(option) => {
                                        return typeof option === "string" ? option : option.code
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li key={option.code} {...props}>
                                            {option.code}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="RefID1" placeholder="" />
                                    )}
                                    onChange={(event, value) => {
                                        setRefID1Value(value);
                                        const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                        newEntitlementCreateShuttle.refID1 = (typeof value === "string" ? value : value?.code);
                                        if (newEntitlementCreateShuttle.refID1) newEntitlementCreateShuttle.refID1 += (noMaj ? "-NOMAJ" : "");
                                        else newEntitlementCreateShuttle.refID1 = (noMaj ? "NOMAJ" : undefined);
                                        setShuttle(newEntitlementCreateShuttle);
                                    }}
                                />
                            }
                        </Grid>

                        {/* NOMAJ */}
                        <Grid item xs={1}>
                            <Tooltip
                                followCursor
                                placement="right-start"
                                enterDelay={1000}
                                leaveDelay={50}
                                title="No MAJ">
                                <Checkbox
                                    checked={noMaj}
                                    icon={<UpdateIcon />}
                                    checkedIcon={<UpdateDisabledIcon />}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                            if (!newEntitlementCreateShuttle.refID1 && event.target.checked === true) {
                                                newEntitlementCreateShuttle.refID1 = "NOMAJ";
                                            }
                                            else {
                                                if (event.target.checked === false) {
                                                    if (newEntitlementCreateShuttle.refID1?.includes("NOMAJ")) {
                                                        if (newEntitlementCreateShuttle.refID1.startsWith("NOMAJ")) {
                                                            newEntitlementCreateShuttle.refID1 = undefined;
                                                        }
                                                        else newEntitlementCreateShuttle.refID1 = newEntitlementCreateShuttle.refID1.replace("-NOMAJ", '');
                                                    }
                                                }
                                                else if (event.target.checked === true) {
                                                    if (!newEntitlementCreateShuttle.refID1?.includes("NOMAJ")) {
                                                        newEntitlementCreateShuttle.refID1 = newEntitlementCreateShuttle.refID1 + "-NOMAJ";
                                                    }
                                                }
                                            }
                                            setShuttle(newEntitlementCreateShuttle);
                                            setNoMaj(event.target.checked);
                                        }
                                    }
                                />
                            </Tooltip>
                        </Grid>

                        {/* Action */}
                        <Grid item xs={2}>
                            <FormControl required>
                                <InputLabel id="lbl-action">Action</InputLabel>
                                <Select
                                    error={false}
                                    labelId="lbl-action"
                                    id="cbo-action"
                                    value={entitlementAction}
                                    label="Action"
                                    style={{ width: 150 }}
                                    autoWidth
                                    onChange={(event: SelectChangeEvent) => {
                                        setEntitlementAction(event.target.value);
                                        const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                        newEntitlementCreateShuttle.entitlementAction = +event.target.value;


                                        let SendEmailVisible = !isInModal
                                            && newEntitlementCreateShuttle.entitlementAction === EntitlementAction.PRODUCE
                                            //&& entitlementState !== EntitlementState.Draft
                                            && entitlementState !== EntitlementState.Queued
                                            && entitlementState !== EntitlementState.ProductKeysGenerated;

                                        if (SendEmailVisible) {
                                            newEntitlementCreateShuttle.sendMail = true;
                                            setSendMail(true);
                                        }
                                        else {
                                            newEntitlementCreateShuttle.sendMail = false;
                                            setSendMail(false);
                                        }
                                        setSendEmailVisible(SendEmailVisible);

                                        setShuttle(newEntitlementCreateShuttle);
                                    }}
                                >
                                    <MenuItem value={EntitlementAction.SAVE}>Draft</MenuItem>
                                    <MenuItem value={EntitlementAction.PRODUCE}>Produce</MenuItem>
                                    <MenuItem value={EntitlementAction.DISABLE}>Disable</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Comments */}
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                inputProps={{ maxLength: 500 }}
                                autoFocus
                                margin="dense"
                                id="Comments"
                                label="Comments"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={description || ''}
                                onChange={(event: any) => {
                                    setDescription(event.target.value);
                                    const newEntitlementCreateShuttle = Object.assign({}, entitlementCreateShuttle);
                                    newEntitlementCreateShuttle.description = event.target.value;
                                    setShuttle(newEntitlementCreateShuttle);
                                }}
                            />
                        </Grid>

                        {/* Button */}
                        {
                            !isInModal &&
                            <>
                                <Grid alignItems="center" container spacing={6}>
                                    {
                                        (entitlementState !== EntitlementState.Draft && entitlementState !== EntitlementState.Queued) &&
                                        <Grid item xs={1}></Grid>
                                    }
                                    <Grid item xs={10}>
                                        <Button type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={(e) => { Save(); }}>
                                            UPDATE</Button>
                                    </Grid>
                                    {
                                        (entitlementState === EntitlementState.Draft || entitlementState === EntitlementState.Queued) &&
                                        <Grid item xs={2}>
                                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                                <div>
                                                    <HtmlTooltip
                                                        arrow
                                                        placement="top"
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        onClose={handleTooltipClose}
                                                        open={openTooltip}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title={
                                                            <React.Fragment>
                                                                <Box sx={{
                                                                    textAlign: "center"
                                                                }}>
                                                                    <Paper elevation={24}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="h6">
                                                                                    Delete entitlement
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="body1">
                                                                                    Are you sure ?
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Button onClick={handleTooltipClose} autoFocus>No</Button>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Button onClick={deleteContact}>Yes</Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Paper>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Button type="submit"
                                                            fullWidth variant="contained"
                                                            sx={{ mt: 3, mb: 2 }} onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleTooltipOpen();
                                                            }} >Delete</Button>
                                                    </HtmlTooltip>
                                                </div>
                                            </ClickAwayListener>
                                        </Grid>
                                    }
                                    {
                                        (entitlementState !== EntitlementState.Draft && entitlementState !== EntitlementState.Queued) &&
                                        <Grid item xs={1}></Grid>
                                    }

                                </Grid>
                            </>
                        }
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={openBackDrop}
                        //</>onClick={() => setOpenBackDrop(false)}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </>
                }
            </Grid>
        </>
    );
});