import * as React from 'react';
import { useEffect, useState } from "react";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IAuthShuttle, ICustomerDetailsShuttle, ICustomerEntitlementsShuttle, IEntitlementsShuttle, IInstance, ISOC_listResponse, SocSentinelWebApi } from '../../Services/soc-sentinel-web-api';
import { useLoaderData } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, ClickAwayListener, Collapse, IconButton, Paper, Skeleton, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, TooltipProps, withStyles } from '@mui/material';
import useIds from '../../Services/idsHook';
import CheckIcon from '@mui/icons-material/Check';
import useDocumentTitle from '../DocumentTitle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import KeyIcon from '@mui/icons-material/Key';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { format } from 'date-fns'
import { useNavigate } from "react-router-dom";
import useEntitlementTools from '../../Services/tools';
import { ContactDialog } from '../Contact/ContactDialog';
import { CustomerDetails } from './CustomerDetails';
import { MessageBox } from '../Tools/MessageBox';
import { CustomerEntitlementList } from './CustomerEntitlementList';
import Favicon from 'react-favicon';
import { CustomerSearchForm } from './CustomerSearchForm';
import { CustomerList } from './CustomerList';
import { CustomerSearch } from './CustomerSearch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import Title from '../Title';

export interface CustomerProps {
    vendorId: number,
}

export interface IContact {
    emailId?: string | null,
    firstName?: string | null,
    middleName?: string | null,
    lastName?: string | null,
    locale?: string | null
}

export interface IAddresses {
    shippingSameAsBilling?: boolean | null,
    addresses?: IAddress[] | null
}

export interface IAddress {
    type?: string | null,
    street?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    zip?: string | null
}

export async function loader(params: any) {
    return {
        customerId: params['params'].customerId as number,
        crmId: params['params'].crmId as string
    };
}

function State(props: any) {

    const [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE, getLineItemType] = useEntitlementTools();

    useEffect(() => {
        if (props) { }
    }, [props]);

    return (
        <>
            <React.Fragment>
                <Tooltip
                    followCursor
                    placement="top"
                    title={getState(props?.entitlement)}>
                    <Box>
                        {
                            props?.entitlement?.entState === 0 && <DriveFileRenameOutlineOutlinedIcon style={{ color: '#0082B4', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 1 && <AccessTimeIcon style={{ color: '#20C997', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 2 && <KeyIcon style={{ color: '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 3 && <DoneAllIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 4 && <DoneIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState === 5 && <DoneAllIcon style={{ color: !props.disabled ? 'green' : '#a1a1a1', fontSize: props.fontSize ? props.fontSize : 20 }} />
                        }
                        {
                            props?.entitlement?.entState !== 5 &&
                            props?.entitlement?.entState !== 4 &&
                            props?.entitlement?.entState !== 3 &&
                            props?.entitlement?.entState !== 2 &&
                            props?.entitlement?.entState !== 1 &&
                            props?.entitlement?.entState !== 0 &&
                            <span>{props?.entitlement?.entState}</span>
                        }
                    </Box>
                </Tooltip>
            </React.Fragment>
        </>
    );
}

function Products(props: any) {
    return (
        <>
            {
                props?.entitlement?.entitlementItem[0]?.itemProduct?.map((k: any) => (
                    <Tooltip
                        key={k.product.productIdentifier}
                        followCursor
                        placement="top"
                        enterDelay={500}
                        leaveDelay={50}
                        title={<Features itemProduct={k}
                        />}>
                        <Box sx={{
                            '&:hover': { color: '#cc141e' },
                            'whiteSpace': 'nowrap'
                        }}>{k.product.productName}</Box>
                    </Tooltip>
                ))
            }
        </>
    );
}

function Features(props: any) {
    return (
        <>
            <Typography color="inherit">{props?.itemProduct?.product?.productName}</Typography>
            <ul>
                {
                    props?.itemProduct?.product?.productFeatureRef?.map((k: any) => (
                        <li key={k.featureId}>{k.featureName}</li>
                    ))
                }
            </ul>
        </>
    );
}

export const Customer: React.FC<CustomerProps> = (props) => {
    const [getIds, secretKey] = useIds();
    const routeData = useLoaderData() as any;
    const [customerDetails, setCustomerDetails] = useState<any | undefined>(undefined);
    const [crmId, setCrmId] = useState<string>("");
    const [customerId, setCustomerId] = useState<string>();
    const [contacts, setContacts] = useState<IInstance[] | undefined>(undefined);
    const [pageSize, setPageSize] = useState<number>(20);
    const [authShuttle, setAuthShuttle] = useState<IAuthShuttle | undefined>(undefined);
    const [openModalContact, setOpenModalContact] = useState(false);
    const [contactModal, setContactModal] = useState<IInstance | undefined>(undefined);
    const [randomDetails, setRandomDetails] = useState<number | undefined>(undefined);
    const [getState, getCONCURRENT_INSTANCES, getEXPIRATION_DATE, getLICENSE_TYPE, getLineItemType] = useEntitlementTools();
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [listCustomers, setListCustomers] = useState<ISOC_listResponse>();

    useDocumentTitle("Customer");
    useEffect(() => {
        let ids = getIds();
        let tempAuthShuttle: IAuthShuttle = { cryptedIds: ids.cryptedIds }
        setAuthShuttle(tempAuthShuttle);

        if (routeData.customerId || routeData.crmId) {
            setCustomerId(routeData.customerId);
            setCrmId(routeData.crmId);

            let shuttleDetails: ICustomerDetailsShuttle = {
                authShuttle: tempAuthShuttle,
                customerId: routeData.customerId,
                crmId: routeData.crmId
            }

            if (!routeData.customerId) {
                let restAPI = new SocSentinelWebApi();
                restAPI.CustomerGetID(shuttleDetails)
                    .then((u) => { setCustomerId(u.toString()); })
                    .catch((ex: any) => {
                        //debugger
                        setAlertMessage(ex);
                        setAlertOpen(true);
                    });
            }

            loadContacts(tempAuthShuttle, routeData.customerId, routeData.crmId);
        }
    }, [routeData]);

    const loadContacts = (authShuttle: IAuthShuttle, customerId: string, crmId: string) => {
        let restAPI = new SocSentinelWebApi();
        restAPI.CustomerContacts({ authShuttle: authShuttle, customerId: customerId, crmId: crmId })
            .then((u) => { setContacts(u); })
            .catch((a) => {
                //debugger;
                setAlertMessage(a);
                setAlertOpen(true);
            });
    }

    const openAddContact = (a: IInstance | undefined) => {
        setOpenModalContact(true);
        if (a) setContactModal(a);
        else setContactModal({});
    };

    const handleAlerteClose = () => {
        setAlertOpen(false);
    };

    return (
        <>
            <MessageBox open={alertOpen} onClose={handleAlerteClose} message={alertMessage} />
            {
                customerId &&
                <ContactDialog open={openModalContact} contactInstance={contactModal} customerId={+customerId} vendorId={props.vendorId} onClose={(e) => {
                    if (e.dataCreatedOrOpdated === true) {
                        if (authShuttle) {
                            loadContacts(authShuttle, customerId, crmId);
                            if (customerDetails?.type === 1) {
                                setRandomDetails(Math.random());
                            }
                        }
                    }
                    setOpenModalContact(false);
                }} />
            }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Accordion sx={{ margin: '0px 0px 0px 0px' }}>
                        <AccordionSummary
                            className='CustomerSearchAccordionSummary'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Stack direction="row" alignItems="center" gap={1}>
                                <SearchIcon sx={{ fontSize: 20 }} />
                                <Typography variant="overline" color="primary">Customer search</Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails
                            className='CustomerSearchAccordionDetails'>
                            <CustomerSearch vendorId={props.vendorId} defaultpageSize={5} browseSearch={false} initWithSearch={false} showAddCustomerAction={false} showFormTitle={false} />
                        </AccordionDetails>
                    </Accordion>


                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        {
                            (customerId || crmId) &&
                            <CustomerDetails customerId={customerId} crmId={crmId} vendorId={props.vendorId} random={randomDetails}
                                onCustomerDetailsLoaded={(r) => setCustomerDetails(r)}
                                onCustomerUpdated={(r) => {
                                    if (authShuttle && customerId) {
                                        loadContacts(authShuttle, customerId, crmId);
                                    }
                                }} />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    {
                        !contacts &&
                        <>
                            <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Typography component="div" variant='h3'>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Typography>
                            </Paper>
                        </>
                    }
                    {
                        contacts &&
                        <TableContainer component={Paper} sx={{ maxHeight: 312 }}>
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ padding: '1px 0px 1px 16px' }}>
                                            <span>Contacts</span>
                                            {
                                                customerDetails?.type === 0 &&
                                                <Tooltip
                                                    followCursor
                                                    placement="right-start"
                                                    enterDelay={1000}
                                                    leaveDelay={50}
                                                    title="Add contact">
                                                    <IconButton onClick={(e) => {
                                                        openAddContact(undefined);
                                                    }}>
                                                        <AddCircleOutlineOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell sx={{ padding: '1px 16px 1px 0px' }} align="right">Default</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        contacts.map((contact) => (
                                            <TableRow
                                                hover
                                                key={contact.emailId}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    "&.MuiTableRow-root:hover": {
                                                        cursor: "pointer"
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    openAddContact(contact);
                                                }}
                                            >
                                                <TableCell
                                                    component="th" scope="row" >
                                                    {contact.name}
                                                    <br />
                                                    {contact.emailId}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        contact.default &&
                                                        <CheckIcon />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Grid>
                <Grid item xs={12}>
                    <CustomerEntitlementList vendorId={props.vendorId} pageSize={pageSize} crmId={crmId} customerId={customerId} />
                </Grid>
            </Grid>
        </>);
}