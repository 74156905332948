export default function useIds() {
    const secretKey = '8jqzXeHQunuKENmDqSID9r0K3Mus7PgQtJaUei45x4c=';
    function getIds(): any {
        let myIdsDeserialized: any = undefined;
        let CryptoJS = require("crypto-js");

        let myIds = sessionStorage.getItem('ids');
        if (!myIds) { myIds = localStorage.getItem('ids'); }

        if (myIds) {
            let myIdsStr = CryptoJS.AES.decrypt(myIds, secretKey).toString(CryptoJS.enc.Utf8)
            myIdsDeserialized = JSON.parse(myIdsStr);
            myIdsDeserialized.cryptedIds = myIds;

            let dateDuJour: Date = new Date();
            let dateDuTokenPlus30Jours: Date = new Date(myIdsDeserialized.date);
            dateDuTokenPlus30Jours.setDate(dateDuTokenPlus30Jours.getDate() + 30);

            if (dateDuJour > dateDuTokenPlus30Jours) {
                myIdsDeserialized = undefined;
                localStorage.removeItem('ids');
                sessionStorage.removeItem('ids');
            }
        }

        return myIdsDeserialized;
    }

    return [getIds, secretKey] as const;
}